import { styled } from '@mui/material';

export const LandingText = styled('label')<{
  fontSize: string;
  color?: string;
  whiteSpace?: string;
  margin?: string;
  lineHeight?: string;
  fontWeight?: string;
  mobileFontSize?: string;
}>`
  display: inline;
  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${({ fontWeight }) => fontWeight || '600'};
  line-height: ${({ lineHeight }) => lineHeight || '1.5'};
  color: ${({ color }) => color || 'inherit'};
  white-space: ${({ whiteSpace }) => whiteSpace || 'normal'};
  margin: ${({ margin }) => margin || '0'};

  ${({ theme }) => theme.breakpoints.down('lg')} {
    font-size: ${({ fontSize, mobileFontSize }) =>
      mobileFontSize ? fontSize : `calc(${fontSize} * 0.8)`};
    line-height: 1.5;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    font-size: ${({ fontSize, mobileFontSize }) =>
      mobileFontSize
        ? `calc((${fontSize} + ${mobileFontSize}) / 2 )`
        : `calc(${fontSize} * 0.7)`};
    line-height: 1.5;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: ${({ fontSize, mobileFontSize }) =>
      mobileFontSize ? mobileFontSize : `calc(${fontSize} * 0.5)`};
    line-height: 1.5;
  }
`;

export const FooterCategory = styled('label')`
  display: inline;
  font-size: 18px;
  font-weight: 600;
  color: #9393a2;
`;

export const FooterTitleText = styled('label')`
  display: block;
  font-size: 12px;
  font-weight: 400;
  color: #9393a2;
`;

export const FooterText = styled('label')`
  display: inline;
  font-size: 12px;
  color: #9393a2;
  cursor: pointer;
`;
