import type { SxProps } from '@mui/material';
import { Box, Divider } from '@mui/material';

import ResponsiveBannerAdCard from 'components/organisms/GoogleAds/ResponsiveBannerAdCard';

import useActiveAuth from 'hooks/useActiveAuth';

import { FooterText } from './Footer';

const MoreDialogFooterText = ({
  footerLabel,
  sx,
}: {
  footerLabel: string;
  sx?: SxProps;
}) => <FooterText footerLabel={footerLabel} sx={{ color: '#9393a2', ...sx }} />;

const MobileMoreDialogFooter = ({ sx }: { sx?: SxProps }) => {
  const { isSubscribedUser } = useActiveAuth();

  return (
    <Box id="gtm-footer-area" component="footer" sx={{ ...sx }}>
      <ResponsiveBannerAdCard
        type="small"
        adId="모든_푸터상단_배너_공통"
        sx={{ my: '20px' }}
      />

      {isSubscribedUser ? null : (
        <Divider sx={{ width: 'calc(100% + 40px)', ml: '-20px' }} />
      )}

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          pt: '20px',
          pb: '40px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            columnGap: '16px',
            label: { cursor: 'pointer' },
            flexWrap: 'wrap',
          }}
        >
          <MoreDialogFooterText footerLabel="termOfUse" />
          <MoreDialogFooterText
            footerLabel="privacyPolicy"
            sx={{ fontWeight: 700 }}
          />
          <MoreDialogFooterText footerLabel="appInquiry" />
          <MoreDialogFooterText footerLabel="businessInquiry" />
          <MoreDialogFooterText footerLabel="advertise" />
          <MoreDialogFooterText footerLabel="broadcastInquiry" />
        </Box>

        <Box>
          <MoreDialogFooterText footerLabel="company" />
          <MoreDialogFooterText footerLabel="address" />
        </Box>

        <MoreDialogFooterText
          footerLabel="copyright"
          sx={{ color: '#aeaeba' }}
        />
      </Box>
    </Box>
  );
};

export default MobileMoreDialogFooter;
