import { useState } from 'react';

import useTranslation from 'next-translate/useTranslation';

import CloseDialogIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import CloseIcon from '@mui/icons-material/Close';
import type {
  DialogProps} from '@mui/material';
import {
  Box,
  Dialog,
  IconButton,
  Typography,
} from '@mui/material';

import CustomCircularProgress from 'components/atoms/CustomCircularProgress';
import SearchTextField from 'components/atoms/SearchTextField';
import Transition from 'components/atoms/Transition';

import {
  APP_BAR_DESKTOP_HEIGHT,
  APP_BAR_MOBILE_HEIGHT,
} from 'utils/getConstantNumber';

import ResponsiveBannerAdCard from './GoogleAds/ResponsiveBannerAdCard';

const DesktopSearchBanner = ({
  isSearchBarOpen,
  onCloseSearchBanner,
}: {
  isSearchBarOpen: boolean;
  onCloseSearchBanner: () => void;
}) => {
  const [isSearchLoading, setIsSearchLoading] = useState(false);

  return isSearchLoading ? (
    <CustomCircularProgress />
  ) : (
    <Box
      id="gtm-search-area"
      className={isSearchBarOpen ? 'searchOpen' : ''}
      sx={{
        position: 'absolute',
        top: {
          xs: `${APP_BAR_MOBILE_HEIGHT}px`,
          sm: `${APP_BAR_DESKTOP_HEIGHT}px`,
        },
        left: 0,
        zIndex: 100,

        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100vh',

        '&.searchOpen': {
          animation: 'searchMode .4s ease-in-out',
        },
        '@keyframes searchMode': {
          '0%': { opacity: 0, mt: -1 },
          '100%': { opacity: 1, mt: 0 },
        },
      }}
    >
      <Box
        sx={{
          width: '100%',
          bgcolor: 'background.default',
        }}
      >
        <Box
          sx={{
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            maxWidth: '1200px',
            height: { sm: '80px', md: '130px' },
            mx: 'auto',
            pb: '8px',
            px: 3,
          }}
        >
          <SearchTextField
            shape="banner"
            onSearchAction={() => {
              setIsSearchLoading(true);
            }}
          />

          <IconButton
            aria-label="open drawer"
            edge="start"
            onClick={onCloseSearchBanner}
            sx={{
              position: 'absolute',
              top: '8px',
              right: '8px',
              width: '40px',
              height: '40px',
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
      <Box
        onClick={onCloseSearchBanner}
        sx={{ flex: 1, bgcolor: 'rgba(0,0,0,.5)' }}
      >
        <ResponsiveBannerAdCard
          adId="모든_검색배너하단_배너_웹"
          sx={{ mt: 5 }}
        />
      </Box>
    </Box>
  );
};

const MobileSearchBanner = ({
  open,
  onCloseSearchBanner,
}: {
  onCloseSearchBanner: () => void;
} & Omit<DialogProps, 'onClose'>) => {
  const { t } = useTranslation('search');

  const [isSearchLoading, setIsSearchLoading] = useState(false);

  return isSearchLoading ? (
    <CustomCircularProgress />
  ) : (
    <Dialog
      id="gtm-search-area"
      fullScreen
      open={open}
      onClose={onCloseSearchBanner}
      TransitionComponent={Transition}
      PaperProps={{
        sx: { bgcolor: 'themeModeColor' },
      }}
    >
      <Box sx={{ p: 2 }}>
        <IconButton onClick={onCloseSearchBanner}>
          <CloseDialogIcon />
        </IconButton>
      </Box>
      <Box sx={{ mt: 2, mb: 1, py: 1 }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'right',
            gap: 2,
            mx: 2.5,
          }}
        >
          <Typography variant="h4Bold">{t('mobile.title')}</Typography>
          <SearchTextField
            shape="mobile"
            onSearchAction={() => {
              setIsSearchLoading(true);
            }}
          />
        </Box>
      </Box>
      <Box sx={{ mb: 'auto', mt: 1 }}>
        <ResponsiveBannerAdCard
          adId="모든_검색배너하단_배너_모바일"
          size="xsmall"
        />
      </Box>
    </Dialog>
  );
};

const SearchBanner = {
  Desktop: DesktopSearchBanner,
  Mobile: MobileSearchBanner,
};

export default SearchBanner;
