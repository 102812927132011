import { useRouter } from 'next/router';

const useNumberSystemConverter = (value: number | undefined) => {
  const router = useRouter();
  const locale = router.locale || 'en';
  let result = '0';

  const numberStandardKo = [
    { standard: 1_000, divider: 1, toFixed: 0, text: '' },
    { standard: 10_000, divider: 1_000, toFixed: 1, text: '천' },
    { standard: 100_000, divider: 10_000, toFixed: 1, text: '만' },
    { standard: 100_000_000, divider: 10_000, toFixed: 0, text: '만' },
    { standard: 1_000_000_000, divider: 100_000_000, toFixed: 1, text: '억' },
    {
      standard: 1_000_000_000_000,
      divider: 100_000_000,
      toFixed: 0,
      text: '억',
    },
  ];
  const numberStandardEn = [
    { standard: 1_000, divider: 1, toFixed: 0, text: '' },
    { standard: 10_000, divider: 1_000, toFixed: 1, text: 'K' },
    { standard: 1_000_000, divider: 1_000, toFixed: 0, text: 'K' },
    { standard: 10_000_000, divider: 1_000_000, toFixed: 1, text: 'M' },
    { standard: 1_000_000_000, divider: 1_000_000, toFixed: 0, text: 'M' },
    { standard: 10_000_000_000, divider: 1_000_000_000, toFixed: 1, text: 'B' },
    {
      standard: 1_000_000_000_000,
      divider: 1_000_000_000,
      toFixed: 0,
      text: 'B',
    },
  ];

  const toFixedNoRound = (value: number, toFixed: number) => {
    const factor = Math.pow(10, toFixed);
    return `${Math.floor(value * factor) / factor}`;
  };

  if (value === undefined) return result;
  try {
    (locale === 'ko' ? numberStandardKo : numberStandardEn).forEach((item) => {
      if (value < item.standard) {
        const convertedNumber = toFixedNoRound(
          value / item.divider,
          item.toFixed,
        );
        result = `${convertedNumber.slice(
          0,
          convertedNumber.endsWith('.0') ? -2 : convertedNumber.length,
        )}${item.text}`;
        throw new Error('break');
      }
    });
  } catch (error) {
    return result;
  }
  return 'error';
};

export default useNumberSystemConverter;
