import { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import useTranslation from 'next-translate/useTranslation';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PeopleIcon from '@mui/icons-material/People';
import {
  Box,
  Collapse,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Skeleton,
  useTheme,
} from '@mui/material';

import PlusBadge from 'components/atoms/PlusBadge';

import useToggle from 'hooks/useToggle';

import ChannelList from './ChannelList';

const OtherChannelListItem = ({
  closeProfileMenu,
}: {
  closeProfileMenu: () => void;
}) => {
  const { t } = useTranslation('common');
  const { isOpen, toggle } = useToggle();
  const {
    isOpen: isListItemOpen,
    open: listItemOpen,
    close: listItemClose,
  } = useToggle();
  const {
    palette: { mode },
  } = useTheme();

  const handleListItemShow = (value: boolean) => {
    if (value) listItemOpen();
    else listItemClose();
  };

  return (
    <ErrorBoundary fallback={<></>}>
      <Suspense fallback={<Skeleton variant="rounded" height={48} />}>
        <Box
          sx={{
            backgroundColor: isOpen ? 'gray.50' : 'transparent',
            borderRadius: '8px',
          }}
        >
          {isListItemOpen && (
            <ListItemButton
              onClick={toggle}
              sx={{
                borderBottomLeftRadius: isOpen ? '0px' : '8px',
                borderBottomRightRadius: isOpen ? '0px' : '8px',
              }}
            >
              <ListItemIcon sx={{ minWidth: 48 }}>
                <PeopleIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                    }}
                  >
                    {t('otherChannel')}
                    <PlusBadge
                      size="small"
                      backgroundColor={mode === 'dark' ? '#1D1D21' : 'white'}
                    />
                  </Box>
                }
              />
              {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </ListItemButton>
          )}
          <Collapse in={isOpen} timeout="auto">
            <ChannelList
              handleListItemShow={handleListItemShow}
              closeProfileMenu={closeProfileMenu}
            />
          </Collapse>
        </Box>
      </Suspense>
    </ErrorBoundary>
  );
};

export default OtherChannelListItem;
