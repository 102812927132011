import useTranslation from 'next-translate/useTranslation';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';

import useChannelApi, {
  GET_MANAGED_CHANNEL_LIST_KEY,
} from 'api/hooks/useChannelApi';

import useActiveAuth from 'hooks/useActiveAuth';
import useManagerAuth from 'hooks/useManagerAuth';

interface ChannelManagerQuitDialogProps {
  isOpen: boolean;
  managerId: number;
  managerNickname: string;
  onCLose: () => void;
}

const ChannelManagerQuitDialog = ({
  isOpen,
  managerId,
  onCLose,
  managerNickname,
}: ChannelManagerQuitDialogProps) => {
  const { user } = useActiveAuth();
  const { clearManagedContext } = useManagerAuth();

  const { deleteInvitedChannel } = useChannelApi();
  const { t } = useTranslation('common');

  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: () => deleteInvitedChannel(managerId),
    onSuccess: async () => {
      onCLose();
      if (managerNickname === user.nickname) {
        await clearManagedContext();
      }
      queryClient.refetchQueries({ queryKey: GET_MANAGED_CHANNEL_LIST_KEY });
      enqueueSnackbar(t('channelQuitDialog.deleteSuccess'), {
        variant: 'success',
      });
    },
    onError: () => {
      enqueueSnackbar(t('channelQuitDialog.deleteFail'), {
        variant: 'error',
      });
    },
  });

  return (
    <Dialog open={isOpen}>
      <DialogContent>
        <Typography variant="h4Bold">{t('channelQuitDialog.title')}</Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          {t('channelQuitDialog.description')}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={onCLose}>
          {mutation.isPending
            ? t('channelQuitDialog.close')
            : t('channelQuitDialog.cancel')}
        </Button>
        <Button
          variant="contained"
          onClick={() => mutation.mutate()}
          disabled={mutation.isPending}
        >
          {mutation.isPending
            ? t('channelQuitDialog.deleting')
            : t('channelQuitDialog.delete')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChannelManagerQuitDialog;
