import { useState } from 'react';

import useTranslation from 'next-translate/useTranslation';

import BackIcon from '@mui/icons-material/ArrowBackIosRounded';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import type {
  DialogProps} from '@mui/material';
import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Toolbar,
  Typography,
  useTheme,
} from '@mui/material';
import { useSuspenseQuery } from '@tanstack/react-query';

import useChannelApi, {
  GET_MANAGED_CHANNEL_LIST_KEY,
} from 'api/hooks/useChannelApi';

import ChannelCard from 'components/atoms/ChannelCard';
import PlusBadge from 'components/atoms/PlusBadge';
import Transition from 'components/atoms/Transition';
import ChannelManagerQuitDialog from 'components/organisms/ChannelManagerQuitDialog';

import useActiveAuth from 'hooks/useActiveAuth';
import useAuth from 'hooks/useAuth';
import useQueryEvents from 'hooks/useQueryEvents';
import useToggle from 'hooks/useToggle';

import type { ManagedChannelOwnerType } from 'types/channel';

const ChannelAccessSelectionDialog = ({
  onClose,
  open,
  handleListItemShow,
}: {
  onClose: () => void;
  handleListItemShow: (value: boolean) => void;
} & Omit<DialogProps, 'onClose' | 'content'>) => {
  const {
    user: { id, nickname: ownerNickname, photoURL },
    channel: { channelName },
  } = useAuth();
  const {
    channel,
    user: activeUser,
    changeActiveAccount,
    role,
  } = useActiveAuth();
  const { getManagedChannelList } = useChannelApi();
  const { t } = useTranslation('common');
  const {
    close: closeChannelManagerQuitDialog,
    isOpen: isChannelManagerQuitDialogOpen,
    open: openChannelManagerQuitDialog,
  } = useToggle();
  const {
    palette: { mode },
  } = useTheme();

  const [selectedChannelManagerId, setSelectedChannelManagerId] =
    useState<number>(NaN);
  const [managerNickname, setManagerNickname] = useState<string>('');

  const { data } = useSuspenseQuery({
    queryKey: GET_MANAGED_CHANNEL_LIST_KEY,
    queryFn: () => getManagedChannelList(),
  });
  useQueryEvents(
    { data },
    {
      onSuccess: (data) => handleListItemShow(data?.length > 0),
    },
  );

  const myChannel: ManagedChannelOwnerType = {
    id: 0,
    owner: {
      channel: {
        channelName,
      },
      nickname: ownerNickname,
      profileUrl: photoURL,
      uid: id,
    },
    accepted: true,
    role: 'owner',
  };

  const myInfoAddedManagedChannelList = [myChannel, ...(data ?? [])];

  const filteredActiveChannelList = myInfoAddedManagedChannelList.filter(
    (channelInfo) => channelInfo.owner.nickname !== activeUser?.nickname,
  );

  return (
    <>
      <Dialog
        fullScreen
        open={open}
        onClose={onClose}
        TransitionComponent={Transition}
        sx={{ zIndex: 1000 }}
      >
        <Toolbar
          disableGutters
          sx={{ px: { xs: 1, sm: 2 }, alignItems: 'center' }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              px: 0.5,
              flex: 1,
            }}
          >
            <IconButton
              size="small"
              sx={{ mr: 1 }}
              onClick={() => {
                onClose();
              }}
            >
              <BackIcon sx={{ width: '20px', height: '20px' }} />
            </IconButton>
            <Typography
              variant="h4Bold"
              sx={{
                mr: 1,
              }}
            >
              {t('otherChannel')}
            </Typography>
            <PlusBadge
              size="small"
              backgroundColor={mode === 'dark' ? '#1D1D21' : 'white'}
            />
          </Box>
        </Toolbar>
        <DialogContent sx={{ px: '20px', pb: 10 }}>
          <Box
            sx={{
              mb: 3,
            }}
          >
            <Typography variant="h5Bold">{t('currentChannel')}</Typography>
            <Typography
              variant="body2"
              sx={{
                mb: 2,
              }}
              color="gray.500"
            >
              {t('currentChannelDescription')}
            </Typography>
            <ChannelCard
              channelName={activeUser?.displayName ?? ''}
              profileUrl={activeUser?.photoURL ?? ''}
              nickname={channel?.nickname ?? ''}
              role={role === 'owner' ? 'owner' : 'manager'}
              trigger={
                <IconButton
                  sx={{
                    '& svg': {
                      marginLeft: '0 !important',
                    },
                  }}
                >
                  <CheckIcon />
                </IconButton>
              }
            />
          </Box>
          <Typography variant="h5Bold">{t('otherChannel')}</Typography>
          <Typography
            variant="body2"
            sx={{
              mb: 2,
            }}
            color="gray.500"
          >
            {t('otherChannelDescription')}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
            }}
          >
            {filteredActiveChannelList?.map((channelInfo) => (
              <Box
                key={channelInfo.owner.nickname}
                onClick={async () => {
                  await changeActiveAccount(channelInfo);
                }}
              >
                <ChannelCard
                  channelName={channelInfo.owner.channel.channelName}
                  nickname={channelInfo.owner.nickname}
                  trigger={
                    ownerNickname !== channelInfo.owner.nickname && (
                      <IconButton
                        sx={{
                          '& svg': {
                            marginLeft: '0 !important',
                          },
                        }}
                        onClick={() => {
                          openChannelManagerQuitDialog();
                          setSelectedChannelManagerId(channelInfo.id);
                          setManagerNickname(channelInfo.owner.nickname);
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    )
                  }
                  profileUrl={channelInfo.owner.profileUrl}
                  role={
                    ownerNickname === channelInfo.owner.nickname
                      ? 'owner'
                      : 'manager'
                  }
                />
              </Box>
            ))}
          </Box>
        </DialogContent>
      </Dialog>
      <ChannelManagerQuitDialog
        isOpen={
          !Number.isNaN(selectedChannelManagerId) &&
          isChannelManagerQuitDialogOpen
        }
        managerId={selectedChannelManagerId}
        onCLose={closeChannelManagerQuitDialog}
        managerNickname={managerNickname}
      />
    </>
  );
};

export default ChannelAccessSelectionDialog;
