import { useCallback, useEffect, useMemo, useState } from 'react';

import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';

import MoreIcon from '@mui/icons-material/AutoAwesomeMosaic';
import MyChannelIcon from '@mui/icons-material/Person';
import { Box, Button, Paper, Typography } from '@mui/material';

import CustomCircularProgress from 'components/atoms/CustomCircularProgress';
import { AddIcon, EventIcon, Home } from 'components/atoms/Icons';
import { NextLinkComposed } from 'components/atoms/Link';
import CreateMenu from 'components/molecules/CreateMenu';

import useActiveAuth from 'hooks/useActiveAuth';

import MoreDialog from './MoreDialog';

const NavigationBar = () => {
  const { t } = useTranslation('common');
  const { query, route } = useRouter();
  const {
    channel,
    isAnonymousUser,
    isAuthProcessComplete,
    isFirebaseAuthenticating,
    isProfileLoadProcessing,
    isProfileLoadedUser,
    signIn,
  } = useActiveAuth();

  const [isSettingDialogOpen, setIsSettingDialogOpen] = useState(false);
  const [isOpenFloatingMenu, setIsOpenFloatingMenu] = useState(false);
  const [underBarLocation, setUnderBarLocation] = useState(0);
  const [currentMenu, setCurrentMenu] = useState<
    'home' | 'event' | 'myChannel' | 'more' | 'none'
  >('none');
  const [isPageLoading, setIsPageLoading] = useState(false);

  const isMyChannel = useMemo(
    () => query.handle === `@${channel.nickname}`,
    [query, channel.nickname],
  );

  const handleBackFromMobile = useCallback(
    (event: { state: { dialogOpen: boolean } }) => {
      if (!event.state || !event.state.dialogOpen) {
        setIsSettingDialogOpen(false);
      }
    },
    [],
  );

  const addStateWindowHistory = () => {
    window.history.pushState(
      { moreDialogOpen: true },
      '',
      window.location.href,
    );
  };

  useEffect(() => {
    window.addEventListener('popstate', handleBackFromMobile);
    return () => window.removeEventListener('popstate', handleBackFromMobile);
  }, [handleBackFromMobile]);

  useEffect(() => {
    const handleResize = () => {
      const buttonWidth =
        (window.innerWidth - 90) / 4 < 70 ? 70 : (window.innerWidth - 90) / 4;

      const calcUnderBarLocation = () => {
        switch (currentMenu) {
          case 'home':
            return 0;
          case 'event':
            return buttonWidth;
          case `myChannel`:
            return (
              buttonWidth * 2 +
              (buttonWidth === 70 ? window.innerWidth - 280 : 90)
            );
          default:
            if (isSettingDialogOpen)
              return (
                buttonWidth * 3 +
                (buttonWidth === 70 ? window.innerWidth - 280 : 90)
              );
            return -200;
        }
      };
      setUnderBarLocation(calcUnderBarLocation());
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [currentMenu, isSettingDialogOpen]);

  useEffect(() => {
    // 로딩 해제 동작
    const handleStopLoading = () => {
      if (
        (currentMenu === 'home' && route === '/') ||
        (currentMenu === 'event' && route === '/event') ||
        (currentMenu === 'myChannel' &&
          route === `/channel/[handle]` &&
          isMyChannel)
      ) {
        setIsSettingDialogOpen(false);
        setIsPageLoading(false);
      }
      if (currentMenu === 'more' && isSettingDialogOpen)
        setIsPageLoading(false);
    };

    handleStopLoading();
  }, [currentMenu, isMyChannel, isSettingDialogOpen, route]);

  useEffect(() => {
    const updateButtonSelectOnURLChange = () => {
      if (route === '/') setCurrentMenu('home');
      else if (route === '/event') setCurrentMenu('event');
      else if (route === `/channel/[handle]` && isMyChannel)
        setCurrentMenu('myChannel');
      else setCurrentMenu('none');
    };

    if (!isSettingDialogOpen && !isPageLoading) {
      updateButtonSelectOnURLChange();
    }
  }, [currentMenu, isMyChannel, isPageLoading, isSettingDialogOpen, route]);

  return (
    <>
      <CustomCircularProgress isLoading={isPageLoading} zIndex={1000} />

      <Paper
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 1001,
          borderRadius: 0,
        }}
        elevation={3}
      >
        <Box
          sx={{
            height: '60px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            bgcolor: 'background.default',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: 'calc((100vw - 90px) / 2)',
              minWidth: '140px',
            }}
          >
            <Button
              component={NextLinkComposed}
              to="/"
              disableRipple
              disableFocusRipple
              disableTouchRipple
              onClick={() => {
                if (route !== '/') setIsPageLoading(true);
                setCurrentMenu('home');
              }}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '80px',
                height: '60px',
                borderRadius: 0,
                borderColor: 'primaryColor.500',
                flex: 1,
                '&:hover:focus, :hover': {
                  bgcolor: 'transparent',
                },
                ':active': { transform: 'scale(0.8)', borderRadius: 2 },
                transition: 'transform .3s',
              }}
            >
              <Home
                sx={{
                  color:
                    currentMenu === 'home' ? 'primaryColor.500' : 'gray.400',
                }}
              />
              <Typography
                variant="chip"
                sx={{
                  color:
                    currentMenu === 'home' ? 'primaryColor.500' : 'gray.600',
                  fontSize: '12px',
                }}
              >
                {t('navigationBar.home')}
              </Typography>
            </Button>

            <Button
              component={NextLinkComposed}
              to="/event"
              disableRipple
              onClick={() => {
                if (route !== '/event') setIsPageLoading(true);
                setCurrentMenu('event');
              }}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '80px',
                height: '60px',
                borderRadius: 0,
                borderColor: 'primaryColor.500',
                flex: 1,
                '&:hover:focus, :hover': {
                  bgcolor: 'transparent',
                },
                ':active': { transform: 'scale(0.8)', borderRadius: 2 },
                transition: 'transform .3s',
              }}
            >
              <EventIcon
                sx={{
                  color:
                    currentMenu === 'event' ? 'primaryColor.500' : 'gray.400',
                }}
              />
              <Typography
                variant="chip"
                sx={{
                  color:
                    currentMenu === 'event' ? 'primaryColor.500' : 'gray.600',
                  fontSize: '12px',
                }}
              >
                {t('navigationBar.events')}
              </Typography>
            </Button>
          </Box>

          <Button
            disableRipple
            sx={{
              position: 'absolute',
              top: '-15%',
              left: '50%',
              transform: 'translate(-50%, 0)',
              borderRadius: '52px',
              width: '48px',
              height: '48px',
              minWidth: '48px',
              background: isAuthProcessComplete
                ? 'linear-gradient(115deg,#37C556 13.75%,#15B3B3 82.09%)'
                : 'linear-gradient(115deg, #A6A6A6 13.75%, #C0C0C0 82.09%)',
              boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.10)',
              color: '#E5E5E9',
              zIndex: 1001,
              '&:hover': {
                background:
                  'linear-gradient(115deg,#37C556 13.75%,#15B3B3 82.09%)',
              },
            }}
            disabled={isFirebaseAuthenticating}
            onClick={() => {
              setIsOpenFloatingMenu(!isOpenFloatingMenu);
            }}
          >
            <AddIcon
              sx={{
                width: '38px',
                height: '38px',
                color: 'white',
                transform: isOpenFloatingMenu
                  ? 'rotate(-45deg)'
                  : 'rotate(0deg)',
                transition: 'transform .3s',
              }}
            />
          </Button>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: 'calc((100vw - 90px) / 2)',
              minWidth: '140px',
            }}
          >
            <Button
              disableRipple
              component={isProfileLoadedUser ? NextLinkComposed : 'div'}
              to={`/channel/@${channel.nickname}`}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '80px',
                height: '60px',
                borderRadius: 0,
                borderColor: 'primaryColor.500',
                flex: 1,
                opacity: isProfileLoadProcessing ? 0.3 : 1,
                '&:hover:focus, :hover': {
                  bgcolor: 'transparent',
                },
                ':active': { transform: 'scale(0.8)', borderRadius: 2 },
                transition: 'transform .3s',
              }}
              disabled={isProfileLoadProcessing}
              onClick={() => {
                if (isAnonymousUser) signIn();
                setCurrentMenu('myChannel');
                if (!isMyChannel) setIsPageLoading(true);
              }}
            >
              <MyChannelIcon
                sx={{
                  color:
                    currentMenu === 'myChannel'
                      ? 'primaryColor.500'
                      : 'gray.400',
                }}
              />
              <Typography
                variant="chip"
                sx={{
                  color:
                    currentMenu === 'myChannel'
                      ? 'primaryColor.500'
                      : 'gray.600',
                  fontSize: '12px',
                }}
              >
                {t('navigationBar.myChannel')}
              </Typography>
            </Button>
            <Button
              disableRipple
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '80px',
                height: '60px',
                borderRadius: 0,
                flex: 1,
                '&:hover:focus, :hover': {
                  bgcolor: 'transparent',
                },
                ':active': { transform: 'scale(0.8)', borderRadius: 2 },
                ':focus:hover': { transform: 'scale(0.8)', borderRadius: 2 },
                transition: 'transform .3s',
              }}
              onClick={() => {
                addStateWindowHistory();
                setIsOpenFloatingMenu(false);
                setIsSettingDialogOpen(true);
                if (!isSettingDialogOpen) setIsPageLoading(true);
                setCurrentMenu('more');
              }}
            >
              <MoreIcon
                sx={{
                  color:
                    currentMenu === 'more' ? 'primaryColor.500' : 'gray.400',
                }}
              />
              <Typography
                variant="chip"
                sx={{
                  color:
                    currentMenu === 'more' ? 'primaryColor.500' : 'gray.600',
                  fontSize: '12px',
                }}
              >
                {t('navigationBar.more')}
              </Typography>
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            transition: '.3s',
            position: 'absolute',
            bottom: 0,
            left: underBarLocation,
            height: '5px',
            borderRadius: '20px',
            width: 'calc((100vw - 90px) / 4)',
            minWidth: '70px',
            background: 'linear-gradient(115deg,#37C556 13.75%,#15B3B3 82.09%)',
          }}
        />

        <CreateMenu.Mobile
          open={isOpenFloatingMenu}
          onClose={() => setIsOpenFloatingMenu(false)}
        />
      </Paper>

      <MoreDialog
        open={isSettingDialogOpen}
        onClose={() => {
          setIsSettingDialogOpen(false);
        }}
      />
    </>
  );
};

export default NavigationBar;
