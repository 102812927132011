import type { ReactElement} from 'react';
import { forwardRef } from 'react';

import { Slide } from '@mui/material';
import type { TransitionProps } from '@mui/material/transitions';

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return (
    <Slide
      direction="left"
      ref={ref}
      easing={{
        enter: 'cubic-bezier(0, .2, .8, 1)',
        exit: 'cubic-bezier(0, .2, .8, 1)',
      }}
      {...props}
    />
  );
});

export default Transition;
