import type { MouseEvent} from 'react';
import { useState } from 'react';

import type {
  SxProps} from '@mui/material';
import {
  Box,
  Drawer,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';

import { MoreIcon } from 'components/atoms/Icons';

import useCheckDevice from 'hooks/useCheckDevice';

const MoreIconButton = ({
  className,
  menuList,
  sx,
}: {
  className?: string;
  menuList: {
    buttonText: string;
    icon: JSX.Element;
    customSx?: SxProps;
    onClick:
      | (() => void)
      | (() => Promise<void>)
      | ((e: MouseEvent<HTMLElement>) => void);
  }[];
  sx?: SxProps;
}) => {
  const { isMobile } = useCheckDevice();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  return (
    <>
      <IconButton
        className={className}
        size="small"
        sx={sx}
        aria-label="more"
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          setAnchorEl(e.currentTarget);
        }}
      >
        <MoreIcon sx={{ height: 24, width: 24 }} />
      </IconButton>

      {isMobile ? (
        <Drawer
          anchor="bottom"
          open={Boolean(anchorEl)}
          onClick={(e) => {
            e.stopPropagation();
          }}
          onClose={() => setAnchorEl(null)}
          PaperProps={{
            sx: { borderTopLeftRadius: 8, borderTopRightRadius: 8 },
          }}
        >
          <Box sx={{ py: 2 }}>
            {menuList.map(({ buttonText, icon, customSx, onClick }) => (
              <MenuItem
                key={buttonText}
                onClick={(e) => {
                  onClick(e);
                  setAnchorEl(null);
                }}
                sx={customSx}
              >
                <ListItemIcon>{icon}</ListItemIcon>
                <Typography variant="body1" sx={{ mx: 1 }}>
                  {buttonText}
                </Typography>
              </MenuItem>
            ))}
          </Box>
        </Drawer>
      ) : (
        <Menu
          id="menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClick={(e) => {
            e.stopPropagation();
          }}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          MenuListProps={{ sx: { padding: 0 } }}
        >
          {menuList.map(({ buttonText, icon, customSx, onClick }) => (
            <MenuItem
              key={buttonText}
              onClick={(e) => {
                onClick(e);
                setAnchorEl(null);
              }}
              sx={customSx}
            >
              <ListItemIcon>{icon}</ListItemIcon>
              <Typography variant="body1" sx={{ mx: 1 }}>
                {buttonText}
              </Typography>
            </MenuItem>
          ))}
        </Menu>
      )}
    </>
  );
};

export default MoreIconButton;
