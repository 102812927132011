import useTranslation from 'next-translate/useTranslation';

import { Avatar, Box, Typography } from '@mui/material';

import useActiveAuth from 'hooks/useActiveAuth';

import type { ChannelCardProps } from './type';

const ChannelCard = ({
  channelName,
  nickname,
  profileUrl,
  accepted,
  role,
  trigger,
}: ChannelCardProps) => {
  const { t } = useTranslation('common');
  const { user } = useActiveAuth();

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: 'gray.50',
        padding: '8px 8px',
        borderRadius: '8px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          flexShrink: 0,
          svg: {
            ml: 0,
          },
        }}
      >
        <Avatar
          src={profileUrl}
          sx={{
            border: `2px solid`,
            borderColor:
              user.nickname === nickname ? 'primaryColor.500' : 'gray.300',
          }}
        />
        <Box sx={{ flexShrink: 0 }}>
          <Typography variant="h6">{channelName}</Typography>
          <Typography
            variant="body3"
            sx={{
              color: 'gray.300',
            }}
          >
            @{nickname}
          </Typography>
          <Typography
            variant="h6"
            sx={{
              color: 'gray.300',
            }}
          >
            {role ? (role === 'owner' ? t('owner') : t('manager')) : null}
          </Typography>
          {accepted !== undefined && (
            <Typography
              variant="h6"
              sx={{
                color: 'gray.300',
              }}
            >
              {/* TODO: 요청 수락시 email 표시 */}
              {accepted === null
                ? t('requestSent')
                : accepted === false && t('requestDenied')}
            </Typography>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {trigger}
      </Box>
    </Box>
  );
};

export default ChannelCard;
