import { memo } from 'react';

import { useRouter } from 'next/router';

import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

const StudioLogoIcon = ({ ...props }: SvgIconProps) => {
  const { locale } = useRouter();

  return locale === 'ko' ? (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="1823"
      height="298"
      viewBox="0 0 1823 298"
      fill="none"
      sx={{ width: '182px', height: '30px' }}
      {...props}
    >
      <path
        d="M110.078 13.7192C114.119 9.79421 118.9 6.71064 124.142 4.6475C129.385 2.58436 134.985 1.58269 140.618 1.70046C146.242 1.81175 151.788 3.03553 156.936 5.30124C162.085 7.56695 166.734 10.8297 170.615 14.9013C174.486 18.9892 177.513 23.8007 179.522 29.0603C181.531 34.3199 182.483 39.9242 182.323 45.5521C182.163 51.18 180.894 56.721 178.589 61.8578C176.284 66.9946 172.989 71.6264 168.891 75.4875L73.4309 166.367C69.4064 170.384 64.619 173.556 59.3507 175.697C54.0824 177.837 48.4396 178.902 42.7535 178.83C37.0675 178.758 31.4534 177.549 26.2411 175.276C21.0289 173.003 16.3238 169.71 12.4026 165.592C8.48139 161.473 5.42283 156.613 3.40744 151.295C1.39204 145.978 0.460362 140.311 0.666795 134.628C0.873228 128.946 2.21351 123.362 4.6094 118.204C7.0053 113.047 10.408 108.421 14.6177 104.598L110.078 13.7192Z"
        fill="url(#paint0_linear_8568_73768)"
      />
      <path
        opacity="0.5"
        d="M132 94.3526C140.437 94.3526 148.684 91.8508 155.699 87.1637C162.714 82.4765 168.181 75.8145 171.41 68.0201C174.638 60.2256 175.483 51.6487 173.837 43.3741C172.191 35.0995 168.129 27.4989 162.163 21.5333C156.198 15.5677 148.597 11.505 140.322 9.85909C132.048 8.21318 123.471 9.05789 115.676 12.2865C107.882 15.515 101.22 20.9824 96.5327 27.9972C91.8455 35.0121 89.3438 43.2593 89.3438 51.696C89.3438 63.0092 93.8381 73.859 101.838 81.8587C109.837 89.8584 120.687 94.3526 132 94.3526Z"
        fill="url(#paint1_radial_8568_73768)"
      />
      <path
        opacity="0.2"
        d="M121.803 104.057C130.241 104.057 138.49 101.554 145.506 96.8643C152.522 92.175 157.99 85.5101 161.217 77.7127C164.444 69.9154 165.286 61.3359 163.636 53.06C161.986 44.7841 157.918 37.1836 151.948 31.22C145.977 25.2563 138.372 21.1975 130.094 19.5572C121.817 17.9168 113.238 18.7686 105.445 22.0047C97.6512 25.2408 90.9926 30.7159 86.3114 37.7373C81.6302 44.7586 79.1368 53.0107 79.1465 61.4494C79.1596 72.7541 83.6591 83.5912 91.6574 91.5803C99.6556 99.5693 110.498 104.057 121.803 104.057Z"
        fill="url(#paint2_radial_8568_73768)"
      />
      <path
        d="M141.014 86.1764C149.451 86.1764 157.698 83.6747 164.713 78.9875C171.728 74.3004 177.195 67.6384 180.424 59.8439C183.653 52.0494 184.497 43.4725 182.851 35.198C181.205 26.9234 177.143 19.3228 171.177 13.3571C165.211 7.3915 157.61 3.32886 149.336 1.68294C141.061 0.0370286 132.485 0.881742 124.691 4.11032C116.896 7.33889 110.234 12.8064 105.546 19.8212C100.859 26.8361 98.3574 35.0832 98.3574 43.5198C98.3574 54.8331 102.852 65.683 110.851 73.6827C118.851 81.6824 129.701 86.1764 141.014 86.1764Z"
        fill="url(#paint3_linear_8568_73768)"
      />
      <path
        d="M196.478 205.477C201.989 206.585 207.665 206.585 213.176 205.477C218.665 204.302 223.866 202.046 228.475 198.841C233.083 195.636 237.008 191.545 240.021 186.808C243.042 182.069 245.086 176.774 246.034 171.233C246.981 165.693 246.812 160.019 245.538 154.545C244.284 149.069 241.947 143.898 238.665 139.339C235.383 134.779 231.221 130.922 226.426 127.996C221.65 125.078 216.343 123.136 210.812 122.282L209.482 122.036L208.152 121.789H206.822L52.5983 95.0919H51.2683C45.7574 93.984 40.081 93.984 34.5701 95.0919C29.0595 96.2751 23.8369 98.5349 19.2019 101.742C14.553 104.919 10.5982 109.008 7.57731 113.76C4.5026 118.513 2.41481 123.836 1.43882 129.411C0.462833 134.987 0.618266 140.702 1.8958 146.217C3.17333 151.731 5.54705 156.933 8.87548 161.511C12.2039 166.09 16.4195 169.952 21.2709 172.869C26.0688 175.815 31.4137 177.759 36.9836 178.583L196.478 205.477Z"
        fill="url(#paint4_linear_8568_73768)"
      />
      <path
        d="M177.269 133.414C181.361 129.584 186.171 126.603 191.421 124.642C196.671 122.68 202.257 121.778 207.858 121.986C213.44 122.179 218.929 123.468 224.014 125.779C229.123 128.157 233.723 131.503 237.56 135.63C243.318 141.791 247.114 149.525 248.465 157.849C249.816 166.172 248.66 174.71 245.145 182.375C242.799 187.489 239.449 192.079 235.294 195.872L138.898 285.766C134.811 289.629 129.999 292.646 124.741 294.641C119.483 296.636 113.881 297.571 108.26 297.39C102.646 297.197 97.1247 295.909 92.0054 293.598C86.8686 291.267 82.2598 287.915 78.4597 283.746C72.6868 277.553 68.8873 269.782 67.5452 261.422C66.2031 253.063 67.379 244.493 70.9232 236.804C73.2586 231.684 76.6102 227.092 80.7746 223.308L177.269 133.414Z"
        fill="url(#paint5_linear_8568_73768)"
      />
      <path
        d="M206.38 207.103C214.809 207.112 223.052 204.622 230.065 199.946C237.078 195.27 242.547 188.619 245.779 180.835C249.012 173.05 249.863 164.481 248.224 156.213C246.586 147.945 242.532 140.349 236.575 134.385C230.618 128.421 223.026 124.358 214.76 122.71C206.494 121.062 197.925 121.903 190.136 125.127C182.348 128.35 175.69 133.811 171.006 140.819C166.322 147.827 163.822 156.066 163.822 164.495C163.822 175.787 168.305 186.617 176.284 194.606C184.264 202.595 195.089 207.09 206.38 207.103Z"
        fill="url(#paint6_linear_8568_73768)"
      />
      <path
        opacity="0.25"
        d="M134.66 274.141C143.099 274.141 151.349 271.638 158.364 266.948C165.38 262.259 170.848 255.594 174.075 247.797C177.302 240 178.144 231.42 176.494 223.144C174.844 214.868 170.776 207.268 164.805 201.304C158.835 195.34 151.23 191.282 142.952 189.641C134.674 188.001 126.096 188.853 118.302 192.089C110.509 195.325 103.85 200.8 99.1688 207.821C94.4876 214.843 91.9942 223.095 92.0039 231.534C92.0039 237.133 93.1078 242.677 95.2521 247.85C97.3964 253.023 100.539 257.722 104.5 261.679C108.462 265.636 113.165 268.773 118.34 270.912C123.515 273.05 129.061 274.147 134.66 274.141Z"
        fill="url(#paint7_linear_8568_73768)"
      />
      <path
        opacity="0.4"
        d="M119.34 288.475C127.776 288.475 136.024 285.973 143.039 281.286C150.054 276.599 155.521 269.936 158.75 262.142C161.978 254.347 162.823 245.771 161.177 237.496C159.531 229.222 155.468 221.621 149.502 215.655C143.537 209.69 135.937 205.627 127.662 203.981C119.388 202.335 110.811 203.18 103.016 206.409C95.2217 209.637 88.5597 215.104 83.8726 222.119C79.1854 229.134 76.6836 237.381 76.6836 245.818C76.6771 251.422 77.7759 256.971 79.9173 262.15C82.0587 267.328 85.2003 272.033 89.1626 275.995C93.1249 279.958 97.8301 283.099 103.008 285.241C108.187 287.382 113.736 288.481 119.34 288.475Z"
        fill="url(#paint8_radial_8568_73768)"
      />
      <path
        d="M109.638 297.489C118.075 297.489 126.322 294.987 133.337 290.3C140.352 285.612 145.819 278.951 149.048 271.156C152.277 263.362 153.121 254.785 151.475 246.51C149.829 238.236 145.767 230.635 139.801 224.669C133.835 218.704 126.235 214.641 117.96 212.995C109.685 211.349 101.108 212.194 93.314 215.422C85.5195 218.651 78.8575 224.119 74.1704 231.133C69.4832 238.148 66.9814 246.395 66.9814 254.832C66.9814 266.145 71.4758 276.995 79.4755 284.995C87.4751 292.995 98.325 297.489 109.638 297.489Z"
        fill="url(#paint9_linear_8568_73768)"
      />
      <path
        d="M395.138 131.123C390.584 147.159 382.469 161.959 371.397 174.42C357.567 189.473 340.936 201.686 322.435 210.378L309.037 191.759C324.673 184.508 338.873 174.497 350.955 162.204C360.555 152.165 367.673 140.02 371.741 126.739C375.889 111.866 377.848 96.4672 377.554 81.0289V77.8271H401.493C401.748 95.7956 399.611 113.718 395.138 131.123ZM313.864 146.147L310.86 125.065L378.046 121.075V140.137L313.864 146.147ZM392.232 97.727H320.12V78.0242H392.232V97.727ZM448.582 236.681H423.954V60.8828H448.582V236.681ZM473.211 148.856H442.031V128.562H473.211V148.856Z"
        fill="themeInverseColor"
      />
      <path
        d="M555.52 192.793H485.525V79.2556H555.52V192.793ZM508.627 173.386H532.517V98.6629H508.43L508.627 173.386ZM583.153 144.768H543.254V125.065H583.153V144.768ZM596.502 228.849H573.203V63.5919H596.502V228.849ZM633.149 236.977H609.456V60.8828H633.149V236.977Z"
        fill="themeInverseColor"
      />
      <path
        d="M739.941 143.881H683.394V183.73H658.765V124.621H715.312V95.806H658.617V76.1032H739.941V143.881ZM716.691 174.913C729.011 174.201 741.279 172.787 753.437 170.677L755.703 190.379C743.087 192.675 730.362 194.319 717.578 195.305C704.919 196.192 690.24 196.586 673.641 196.537H658.864V175.799H673.641C690.29 175.799 704.672 175.799 716.888 174.913H716.691ZM790.281 237.469H765.948V60.8828H790.281V237.469ZM816.388 147.871H784.863V127.478H816.388V147.871Z"
        fill="themeInverseColor"
      />
      <path
        d="M878.349 175.504C896.18 174.913 912.862 173.665 928.394 171.76L929.527 189.345C913.041 192.236 896.394 194.128 879.679 195.01C862.341 195.945 843.525 196.438 823.231 196.586L820.521 176.39C841.209 176.39 860.485 176.095 878.349 175.504ZM923.075 97.1359H824.955V77.4331H923.075V97.1359ZM864.311 181.907H840.422V92.8506H864.311V181.907ZM907.312 181.907H883.62V92.8506H907.411L907.312 181.907ZM961.495 236.977H936.867V60.8828H961.495V236.977ZM987.602 148.314H956.077V128.217H987.602V148.314Z"
        fill="themeInverseColor"
      />
      <path
        d="M1069.37 81.0781C1076.8 86.7292 1082.51 94.3355 1085.87 103.047C1093.81 124.399 1093.81 147.896 1085.87 169.248C1082.51 177.931 1076.8 185.505 1069.37 191.118C1062.39 196.116 1054.02 198.803 1045.43 198.803C1036.84 198.803 1028.47 196.116 1021.49 191.118C1014.06 185.495 1008.33 177.926 1004.94 169.248C996.93 147.908 996.93 124.387 1004.94 103.047C1008.33 94.3422 1014.06 86.7409 1021.49 81.0781C1028.46 76.0524 1036.84 73.348 1045.43 73.348C1054.02 73.348 1062.4 76.0524 1069.37 81.0781ZM1033.61 100.288C1029.75 104.074 1027.01 108.845 1025.68 114.08C1023.68 121.245 1022.75 128.663 1022.92 136.098C1022.75 143.501 1023.68 150.888 1025.68 158.018C1027.03 163.248 1029.77 168.014 1033.61 171.81C1036.83 174.842 1041.1 176.518 1045.53 176.489C1049.91 176.505 1054.13 174.828 1057.3 171.81C1061.13 168.001 1063.86 163.239 1065.23 158.018C1067.25 150.891 1068.19 143.503 1068.04 136.098C1068.19 128.661 1067.24 121.242 1065.23 114.08C1063.87 108.867 1061.15 104.107 1057.35 100.288C1055.79 98.7698 1053.94 97.5781 1051.91 96.7831C1049.88 95.9881 1047.71 95.6057 1045.53 95.6583C1041.1 95.5696 1036.82 97.2337 1033.61 100.288ZM1141.14 237.469H1116.06V60.8828H1141.14V237.469Z"
        fill="themeInverseColor"
      />
      <path
        d="M1352.52 209.186H1354.41V207.292V200.2V198.305H1352.52H1211.43H1209.53V200.2V207.292V209.186H1211.43H1352.52ZM1276.21 129.796C1278.23 127.132 1279.96 124.287 1281.4 121.308C1282.84 124.289 1284.58 127.135 1286.62 129.797C1293.26 138.813 1301.56 146.485 1311.07 152.406C1319.72 157.998 1329.15 162.267 1339.06 165.071L1340.46 165.466L1341.2 164.218L1345.43 157.127L1346.75 154.917L1344.25 154.314C1335.5 152.206 1327.14 148.715 1319.49 143.971L1319.47 143.957L1319.44 143.945C1310.67 138.821 1302.92 132.12 1296.58 124.181L1296.56 124.159L1296.54 124.137C1290.76 117.322 1287.51 108.715 1287.35 99.7784V87.3534V85.4589H1286.29V85.3346H1284.4H1277H1275.1V87.229V99.6627C1275.03 108.602 1271.84 117.236 1266.09 124.082L1266.07 124.102L1266.06 124.122C1259.81 131.984 1252.17 138.621 1243.5 143.697L1243.48 143.71L1243.46 143.724C1235.77 148.514 1227.37 152.047 1218.57 154.191L1216.08 154.798L1217.39 157.002L1221.62 164.094L1222.36 165.34L1223.76 164.947C1233.71 162.146 1243.19 157.877 1251.88 152.282C1261.33 146.384 1269.59 138.755 1276.21 129.796Z"
        fill="themeInverseColor"
        stroke="themeInverseColor"
        strokeWidth="3.78882"
      />
      <path
        d="M1507.73 179.077H1509.63V177.183V169.717V167.823H1507.73H1366.83H1364.93V169.717V177.183V179.077H1366.83H1404.5V229.624V231.519H1406.39H1414.85H1416.75V229.624V179.077H1457.75V229.624V231.519H1459.64H1467.92H1469.8L1469.81 229.635L1470.09 179.077H1507.73ZM1489.44 92.5448H1491.34V90.6504V83.3721V81.4777H1489.44H1386.61H1384.72V83.3721V141.073V142.968V146.638V148.533V150.371V152.265H1386.61H1491.18H1493.08V150.371V142.968V141.073H1491.18H1396.78V121.472H1485.58H1487.48V119.577V112.486V110.591H1485.58H1396.78V92.5448H1489.44Z"
        fill="themeInverseColor"
        stroke="themeInverseColor"
        strokeWidth="3.78882"
      />
      <path
        d="M1605.09 100.32H1606.98V98.4258V90.9609V89.0665H1605.09H1532.93H1531.03V90.9609V180.949V182.843V185.518V187.412V190.432V192.327H1532.93L1544.87 192.327L1544.88 192.327C1560.71 192.264 1574.25 191.828 1585.69 191.016C1597.61 190.187 1609.47 188.704 1621.23 186.573L1622.99 186.253L1622.77 184.472L1621.84 177.069L1621.58 175.085L1619.62 175.442C1608.2 177.516 1596.68 178.961 1585.11 179.771L1585.11 179.784C1573.88 180.559 1560.47 180.949 1544.87 180.949H1543.28V100.32H1605.09ZM1648.07 231.58H1649.97V229.686V75.6575V73.7631H1648.07H1639.74H1637.84V75.6575V229.686V231.58H1639.74H1648.07Z"
        fill="themeInverseColor"
        stroke="themeInverseColor"
        strokeWidth="3.78882"
      />
      <path
        d="M1819.21 210.43H1821.1V208.536V201.133V199.239H1819.21H1754.11V166.626C1762.01 166.106 1769.87 164.54 1777.46 161.929L1777.47 161.925C1785.41 159.139 1792.45 154.254 1797.83 147.788L1797.85 147.775L1797.86 147.761C1802.76 141.645 1805.37 134.008 1805.24 126.171C1805.36 118.3 1802.76 110.63 1797.86 104.465L1797.85 104.449L1797.84 104.434C1792.46 97.9469 1785.42 93.0394 1777.48 90.2328L1777.47 90.2301C1758.51 83.6279 1737.88 83.6279 1718.93 90.2301L1718.92 90.2342L1718.9 90.2385C1710.99 93.1139 1703.97 98.0301 1698.56 104.489L1698.54 104.514L1698.52 104.54C1693.71 110.711 1691.09 118.313 1691.09 126.141C1691.09 133.968 1693.71 141.57 1698.52 147.741L1698.54 147.765L1698.56 147.788C1703.95 154.254 1710.98 159.139 1718.92 161.925L1718.94 161.929C1726.49 164.527 1734.3 166.09 1742.17 166.618V199.239H1678.12H1676.23V201.133V208.536V210.43H1678.12H1819.21ZM1724.51 100.034L1724.52 100.032C1739.84 94.7655 1756.49 94.7654 1771.81 100.031C1778.05 102.178 1783.64 105.87 1788.06 110.761C1791.76 114.959 1793.8 120.36 1793.8 125.954C1793.8 131.551 1791.75 136.955 1788.05 141.153C1783.67 146.098 1778.08 149.815 1771.82 151.936L1771.81 151.938L1771.81 151.941C1756.48 157.276 1739.79 157.276 1724.47 151.941L1724.46 151.938L1724.45 151.935C1718.2 149.831 1712.63 146.114 1708.28 141.159L1708.28 141.155C1704.58 136.957 1702.54 131.551 1702.54 125.954C1702.54 120.359 1704.58 114.956 1708.28 110.757C1712.67 105.843 1718.27 102.149 1724.51 100.034Z"
        fill="themeInverseColor"
        stroke="themeInverseColor"
        strokeWidth="3.78882"
      />
      <defs>
        <linearGradient
          id="paint0_linear_8568_73768"
          x1="34.9613"
          y1="140.458"
          x2="171.058"
          y2="19.581"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.22" stopColor="#80BB25" />
          <stop offset="0.32" stopColor="#86BE26" />
          <stop offset="0.46" stopColor="#99C52A" />
          <stop offset="0.63" stopColor="#B6D130" />
          <stop offset="0.81" stopColor="#E0E238" />
          <stop offset="0.85" stopColor="#EAE63A" />
        </linearGradient>
        <radialGradient
          id="paint1_radial_8568_73768"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(109.622 51.9261) rotate(145.431) scale(44.1674 44.1674)"
        >
          <stop stopColor="#C7E63A" stopOpacity="0.8" />
          <stop offset="0.32" stopColor="#D3E63A" stopOpacity="0.87" />
          <stop offset="1" stopColor="#EBE63A" />
        </radialGradient>
        <radialGradient
          id="paint2_radial_8568_73768"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(197.292 75.9369) rotate(130.1) scale(76.6287 76.6287)"
        >
          <stop stopColor="#C7E63A" stopOpacity="0.8" />
          <stop offset="0.32" stopColor="#D3E63A" stopOpacity="0.87" />
          <stop offset="1" stopColor="#EBE63A" />
        </radialGradient>
        <linearGradient
          id="paint3_linear_8568_73768"
          x1="175.987"
          y1="19.4332"
          x2="105.746"
          y2="67.8532"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C7E63A" />
          <stop offset="1" stopColor="#EBE63A" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_8568_73768"
          x1="1.27208"
          y1="128.784"
          x2="245.488"
          y2="170.997"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#80BB25" />
          <stop offset="0.18" stopColor="#7DBB2B" />
          <stop offset="0.36" stopColor="#5BB65F" />
          <stop offset="0.45" stopColor="#4AB478" />
          <stop offset="0.72" stopColor="#31B19B" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_8568_73768"
          x1="104.861"
          y1="261.137"
          x2="236.033"
          y2="134.202"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.18" stopColor="#1E2C70" />
          <stop offset="0.19" stopColor="#1F2E71" />
          <stop offset="0.55" stopColor="#437DA3" />
          <stop offset="0.8" stopColor="#5AAEC2" />
          <stop offset="0.93" stopColor="#63C1CE" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_8568_73768"
          x1="183.574"
          y1="186.267"
          x2="236.821"
          y2="135.483"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.3" stopColor="#71C3D1" stopOpacity="0" />
          <stop offset="0.39" stopColor="#71C3D1" stopOpacity="0.02" />
          <stop offset="0.48" stopColor="#70C4D1" stopOpacity="0.07" />
          <stop offset="0.57" stopColor="#6FC5D1" stopOpacity="0.17" />
          <stop offset="0.66" stopColor="#6EC6D2" stopOpacity="0.3" />
          <stop offset="0.75" stopColor="#6CC7D2" stopOpacity="0.47" />
          <stop offset="0.84" stopColor="#6AC9D2" stopOpacity="0.68" />
          <stop offset="0.93" stopColor="#67CBD3" stopOpacity="0.92" />
          <stop offset="0.95" stopColor="#66CCD3" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_8568_73768"
          x1="109.293"
          y1="251.286"
          x2="169.633"
          y2="204.196"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.51" stopColor="#09175F" stopOpacity="0.03" />
          <stop offset="1" stopColor="#1D2B6F" />
        </linearGradient>
        <radialGradient
          id="paint8_radial_8568_73768"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(121.074 188.961) rotate(-0.316804) scale(76.9691 76.9692)"
        >
          <stop offset="0.13" stopColor="#09175F" />
          <stop offset="0.52" stopColor="#0D1B62" />
          <stop offset="0.9" stopColor="#19276C" />
          <stop offset="1" stopColor="#1D2B6F" />
        </radialGradient>
        <linearGradient
          id="paint9_linear_8568_73768"
          x1="133.528"
          y1="218.037"
          x2="86.9802"
          y2="289.657"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.06" stopColor="#09175F" />
          <stop offset="0.52" stopColor="#122066" />
          <stop offset="0.9" stopColor="#1D2B6F" />
        </linearGradient>
      </defs>
    </SvgIcon>
  ) : (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 2220 307"
      width="2220"
      height="307"
      fill="none"
      sx={{ width: '222px', height: '30px' }}
      {...props}
    >
      <path
        d="M112.614 13.4086C116.766 9.35068 121.691 6.16799 127.096 4.04964C132.501 1.91623 138.276 0.878692 144.085 0.997078C149.894 1.11546 155.622 2.38748 160.935 4.73931C166.273 7.07971 171.089 10.4643 175.098 14.694C179.108 18.9237 182.231 23.9129 184.283 29.3678C186.355 34.7876 187.342 40.5614 187.189 46.3616C187.035 52.181 185.712 57.9099 183.298 63.2074C180.959 68.4834 177.613 73.2521 173.447 77.2457L74.9328 170.834C70.7476 174.819 65.8185 177.94 60.4271 180.02C55.0357 182.099 49.2875 183.097 43.5107 182.955C37.7338 182.813 32.0412 181.535 26.7583 179.193C21.4755 176.851 16.7057 173.492 12.7211 169.307C8.7365 165.122 5.61504 160.193 3.53532 154.802C1.45561 149.41 0.458374 143.662 0.600166 137.885C0.741958 132.108 2.02007 126.416 4.36179 121.133C6.7035 115.85 10.0629 111.08 14.2481 107.095L112.614 13.4086Z"
        fill="url(#paint0_linear_8568_65782)"
      />
      <path
        opacity="0.5"
        d="M135.228 96.6039C143.927 96.6039 152.432 94.0242 159.665 89.1909C166.899 84.3576 172.537 77.4878 175.866 69.4503C179.195 61.4128 180.066 52.5686 178.369 44.0361C176.672 35.5035 172.482 27.6659 166.331 21.5143C160.179 15.3627 152.341 11.1733 143.809 9.47605C135.276 7.77883 126.432 8.64996 118.395 11.9792C110.357 15.3084 103.487 20.9463 98.6541 28.1798C93.8208 35.4134 91.2412 43.9177 91.2412 52.6174C91.2412 64.2834 95.8755 75.4715 104.125 83.7206C112.374 91.9696 123.562 96.6039 135.228 96.6039Z"
        fill="url(#paint1_radial_8568_65782)"
      />
      <path
        opacity="0.2"
        d="M124.732 106.653C133.445 106.662 141.966 104.087 149.215 99.2516C156.464 94.4167 162.116 87.5397 165.455 79.4913C168.794 71.4428 169.67 62.5846 167.973 54.0379C166.275 45.4912 162.08 37.6402 155.919 31.4787C149.757 25.3173 141.906 21.1222 133.36 19.4247C124.813 17.7271 115.955 18.6033 107.906 21.9424C99.8578 25.2815 92.981 30.9332 88.146 38.1824C83.3111 45.4316 80.7354 53.9524 80.7451 62.666C80.7451 74.332 85.3794 85.5201 93.6285 93.7691C101.878 102.018 113.066 106.653 124.732 106.653Z"
        fill="url(#paint2_radial_8568_65782)"
      />
      <path
        d="M144.483 88.1813C153.182 88.1813 161.687 85.6016 168.92 80.7683C176.154 75.935 181.792 69.0652 185.121 61.0277C188.45 52.9902 189.321 44.146 187.624 35.6135C185.927 27.0809 181.737 19.2432 175.586 13.0916C169.434 6.93994 161.596 2.75068 153.064 1.05345C144.531 -0.643782 135.687 0.227349 127.65 3.55658C119.612 6.88582 112.742 12.5237 107.909 19.7572C103.076 26.9908 100.496 35.4951 100.496 44.1948C100.496 55.8608 105.13 67.0489 113.379 75.2979C121.629 83.547 132.817 88.1813 144.483 88.1813Z"
        fill="url(#paint3_linear_8568_65782)"
      />
      <path
        d="M201.768 211.225C207.44 212.382 213.287 212.382 218.959 211.225C224.625 210.025 229.993 207.702 234.747 204.394C239.502 201.086 243.546 196.861 246.641 191.966C249.752 187.08 251.859 181.624 252.839 175.915C253.82 170.207 253.654 164.36 252.352 158.717C251.05 153.073 248.638 147.745 245.256 143.043C241.873 138.341 237.589 134.359 232.652 131.33C227.728 128.276 222.229 126.264 216.496 125.419L215.166 125.173L213.787 124.927L212.408 124.68L53.3074 97.1949L51.9773 96.9487C46.2701 95.814 40.3953 95.814 34.6881 96.9487C26.1229 98.7447 18.2947 103.071 12.2173 109.368C6.13983 115.665 2.09332 123.642 0.602312 132.266C-0.35409 138.009 -0.170048 143.884 1.14407 149.555C2.44613 155.219 4.8752 160.563 8.28639 165.268C11.7003 169.969 16.0146 173.944 20.9783 176.963C25.9419 179.982 31.456 181.984 37.2002 182.853L201.768 211.225Z"
        fill="url(#paint4_linear_8568_65782)"
      />
      <path
        d="M181.92 136.896C190.495 129.011 201.828 124.819 213.47 125.224C225.112 125.63 236.126 130.601 244.131 139.064C248.067 143.358 251.128 148.377 253.146 153.841C256.191 161.993 256.749 170.866 254.75 179.336C252.751 187.806 248.284 195.492 241.915 201.423L142.514 293.977C136.165 299.982 128.171 303.961 119.552 305.404C110.933 306.847 102.079 305.69 94.1197 302.081C86.1607 298.471 79.4573 292.573 74.8646 285.137C70.2719 277.702 67.998 269.067 68.3332 260.334C68.5405 254.543 69.8967 248.85 72.323 243.587C74.7778 238.331 78.223 233.598 82.4699 229.647L181.92 136.896Z"
        fill="url(#paint5_linear_8568_65782)"
      />
      <path
        d="M211.97 212.999C220.66 212.999 229.155 210.422 236.38 205.594C243.605 200.766 249.237 193.904 252.562 185.875C255.888 177.847 256.758 169.013 255.063 160.49C253.367 151.967 249.183 144.138 243.038 137.993C236.893 131.848 229.064 127.664 220.541 125.968C212.018 124.273 203.184 125.143 195.155 128.469C187.127 131.794 180.265 137.426 175.437 144.651C170.609 151.877 168.032 160.371 168.032 169.061C168.032 180.714 172.661 191.89 180.901 200.13C189.141 208.369 200.317 212.999 211.97 212.999Z"
        fill="url(#paint6_linear_8568_65782)"
      />
      <path
        opacity="0.25"
        d="M137.932 281.958C146.632 281.958 155.136 279.379 162.369 274.545C169.603 269.712 175.241 262.842 178.57 254.805C181.899 246.767 182.77 237.923 181.073 229.39C179.376 220.858 175.187 213.02 169.035 206.869C162.883 200.717 155.046 196.528 146.513 194.83C137.981 193.133 129.136 194.004 121.099 197.334C113.061 200.663 106.192 206.301 101.358 213.534C96.5249 220.768 93.9453 229.272 93.9453 237.972C93.9453 249.638 98.5796 260.826 106.829 269.075C115.078 277.324 126.266 281.958 137.932 281.958Z"
        fill="url(#paint7_linear_8568_65782)"
      />
      <path
        opacity="0.4"
        d="M122.124 296.735C130.824 296.735 139.328 294.156 146.562 289.322C153.795 284.489 159.433 277.619 162.762 269.582C166.092 261.544 166.963 252.7 165.266 244.167C163.568 235.635 159.379 227.797 153.228 221.646C147.076 215.494 139.238 211.305 130.706 209.607C122.173 207.91 113.329 208.781 105.291 212.111C97.2538 215.44 90.3842 221.078 85.5509 228.311C80.7176 235.545 78.1377 244.049 78.1377 252.749C78.1377 264.415 82.772 275.603 91.0211 283.852C99.2701 292.101 110.458 296.735 122.124 296.735Z"
        fill="url(#paint8_radial_8568_65782)"
      />
      <path
        d="M112.124 306.144C120.828 306.144 129.336 303.561 136.572 298.724C143.808 293.886 149.446 287.01 152.772 278.967C156.098 270.924 156.963 262.075 155.258 253.539C153.553 245.004 149.354 237.167 143.193 231.019C137.031 224.871 129.184 220.69 120.645 219.004C112.106 217.318 103.259 218.203 95.2229 221.547C87.1871 224.892 80.3243 230.545 75.5029 237.791C70.6815 245.038 68.1183 253.552 68.1378 262.256C68.1639 273.904 72.8095 285.067 81.0557 293.295C89.302 301.523 100.475 306.144 112.124 306.144Z"
        fill="url(#paint9_linear_8568_65782)"
      />
      <path
        d="M498.625 239.833C481.672 247.09 463.31 250.456 444.885 249.685C432.384 250.424 419.865 248.55 408.127 244.183C396.39 239.815 385.691 233.049 376.714 224.317C368.317 215.299 361.811 204.691 357.581 193.118C353.351 181.544 351.482 169.241 352.085 156.934C350.915 136.753 356.212 116.726 367.205 99.7619C378.198 82.7982 394.315 69.7824 413.213 62.6066C425.675 57.8174 438.983 55.6217 452.323 56.1539C468.084 55.7588 483.796 58.0746 498.773 63.0006V103.096C485.712 95.5474 470.855 91.6672 455.771 91.8653C447.879 91.5397 440.003 92.8459 432.638 95.7021C425.273 98.5582 418.577 102.903 412.967 108.465C402.612 120.771 396.853 136.291 396.673 152.373C396.493 168.455 401.905 184.1 411.982 196.635C417.343 202.057 423.788 206.287 430.896 209.048C438.018 211.797 445.633 213.038 453.259 212.693C469.237 212.814 484.954 208.647 498.773 200.625L498.625 239.833Z"
        fill="themeInverseColor"
      />
      <path
        d="M637.232 249.734H598.861V227.224C594.922 234.336 589.08 240.21 581.989 244.186C574.898 248.162 566.839 250.083 558.716 249.734C553.112 250.089 547.495 249.284 542.215 247.37C536.956 245.454 532.133 242.506 528.029 238.7C524.227 234.812 521.271 230.179 519.346 225.093C517.422 220.007 516.571 214.577 516.848 209.146C516.848 183.188 532.216 168.312 563.15 164.273L597.629 160.283C597.629 145.506 589.601 138.315 573.641 138.315C557.41 138.353 541.567 143.279 528.177 152.451V120.582C535.713 117.071 543.631 114.443 551.771 112.75C560.973 110.684 570.368 109.594 579.798 109.499C618.219 109.499 637.413 128.595 637.38 166.785L637.232 249.734ZM598.861 192.497V183.533L574.232 186.784C560.785 188.458 553.889 194.418 553.889 205.846C553.825 207.997 554.246 210.135 555.121 212.102C555.97 214.096 557.249 215.878 558.864 217.323C562.57 220.423 567.285 222.053 572.114 221.904C575.705 222.026 579.281 221.372 582.596 219.987C585.911 218.601 588.888 216.516 591.324 213.875C596.096 207.685 598.221 199.857 597.235 192.103L598.861 192.497Z"
        fill="themeInverseColor"
      />
      <path
        d="M876.67 249.734H835.195V170.332C835.195 151.27 828.053 141.123 813.67 141.123C810.366 141.052 807.096 141.815 804.164 143.339C801.23 144.872 798.75 147.148 796.972 149.94C792.641 156.331 790.482 163.948 790.815 171.662V249.734H749.193V169.396C749.193 150.284 742.05 141.123 728.061 141.123C724.656 141.039 721.28 141.765 718.21 143.241C715.253 144.724 712.688 146.884 710.723 149.546C706.147 156.202 703.913 164.189 704.369 172.253V249.734H662.895V112.751H704.369V133.537C709.013 126.084 715.502 119.956 723.207 115.745C730.913 111.534 739.575 109.383 748.355 109.5C756.575 108.993 764.741 111.133 771.654 115.607C778.579 120.042 783.871 126.613 786.727 134.325C791.28 126.49 797.893 120.052 805.847 115.71C813.801 111.368 822.793 109.289 831.846 109.697C861.055 109.697 875.833 127.626 875.833 163.337L876.67 249.734Z"
        fill="themeInverseColor"
      />
      <path
        d="M1027.35 191.562H939.817C940.023 195.659 941.08 199.668 942.92 203.334C944.761 207.024 947.343 210.294 950.506 212.939C953.696 215.555 957.382 217.499 961.342 218.653C965.32 219.822 969.499 220.141 973.608 219.589C986.997 220.122 1000.09 215.564 1010.25 206.831L1025.03 221.904C1018.5 230.983 1009.81 238.297 999.753 243.189C989.694 248.081 978.577 250.398 967.401 249.931C958.174 250.525 948.923 249.235 940.211 246.138C931.512 243.046 923.522 238.239 916.716 232.002C910.547 225.282 905.791 217.393 902.727 208.802C899.636 200.224 898.328 191.106 898.884 182.006C898.314 172.374 899.725 162.728 903.029 153.663C906.333 144.598 911.461 136.306 918.095 129.301C924.326 123.017 931.783 118.082 940.003 114.802C948.222 111.523 957.029 109.969 965.874 110.238C974.257 109.706 982.657 110.982 990.503 113.982C998.329 116.97 1005.43 121.587 1011.34 127.528C1022.5 140.555 1028.2 157.39 1027.25 174.519L1027.35 191.562ZM988.976 166.342C988.976 147.28 981.292 137.527 965.529 137.527C962.253 137.57 959.026 138.327 956.072 139.743C953.114 141.157 950.506 143.21 948.437 145.753C943.429 151.552 940.288 158.728 939.423 166.342H988.976Z"
        fill="themeInverseColor"
      />
      <path
        d="M1136.41 150.136C1131.05 147.221 1125.02 145.761 1118.92 145.9C1114.86 145.762 1110.82 146.589 1107.15 148.314C1103.48 150.039 1100.29 152.624 1097.84 155.85C1092.28 163.672 1089.56 173.165 1090.15 182.744V249.734H1049.42V112.75H1090.89V137.379C1092.84 129.408 1097.46 122.349 1103.99 117.38C1110.54 112.417 1118.59 109.828 1126.8 110.041C1130.35 109.895 1133.88 110.482 1137.19 111.765L1136.41 150.136Z"
        fill="themeInverseColor"
      />
      <path
        d="M1265.41 249.734H1227.04V227.224C1223.14 234.328 1217.33 240.201 1210.27 244.178C1203.21 248.156 1195.18 250.081 1187.09 249.734C1181.36 250.214 1175.6 249.479 1170.17 247.578C1164.75 245.676 1159.79 242.651 1155.61 238.701C1151.74 234.879 1148.72 230.279 1146.75 225.204C1144.78 220.143 1143.94 214.714 1144.28 209.294C1144.28 183.336 1159.65 168.46 1190.54 164.421L1226.44 160.431C1226.44 145.654 1218.56 138.463 1202.65 138.463C1186.35 138.509 1170.43 143.432 1156.94 152.599V120.73C1164.49 117.212 1172.43 114.583 1180.59 112.898C1189.6 110.88 1198.79 109.791 1208.02 109.647C1246.41 109.647 1265.6 128.743 1265.6 166.933L1265.41 249.734ZM1227.04 192.498V183.533L1202.65 186.784C1189.21 188.458 1183.44 194.419 1183.44 205.846C1183.4 207.995 1183.82 210.128 1184.67 212.102C1185.57 214.072 1186.84 215.847 1188.42 217.323C1192.12 220.423 1196.84 222.053 1201.67 221.904C1205.26 222.038 1208.85 221.382 1212.16 219.983C1215.48 218.621 1218.46 216.535 1220.88 213.875C1223.23 210.812 1224.94 207.303 1225.9 203.561C1226.85 199.818 1227.04 195.92 1226.44 192.103L1227.04 192.498Z"
        fill="themeInverseColor"
      />
      <path
        d="M1408.45 97.9733H1342V139.743H1403.08V173.928H1342V250.276H1299.79V63.4934H1408.45V97.9733Z"
        fill="themeInverseColor"
      />
      <path
        d="M1453.76 98.5647C1450.75 98.6873 1447.74 98.2107 1444.9 97.1623C1442.07 96.114 1439.47 94.5147 1437.26 92.4569C1435.12 90.5383 1433.44 88.1652 1432.34 85.5116C1431.21 82.884 1430.66 80.046 1430.71 77.1871C1430.62 74.3461 1431.16 71.5198 1432.29 68.912C1433.4 66.305 1435.08 63.9816 1437.21 62.1145C1441.87 58.2963 1447.7 56.2095 1453.72 56.2095C1459.73 56.2095 1465.56 58.2963 1470.22 62.1145C1472.36 63.9935 1474.04 66.3347 1475.14 68.9612C1476.22 71.5659 1476.72 74.3711 1476.62 77.1871C1476.71 80.0711 1476.19 82.9416 1475.09 85.6101C1473.99 88.2919 1472.31 90.6971 1470.17 92.6539C1465.7 96.7142 1459.8 98.8392 1453.76 98.5647ZM1474.35 249.734H1432.88V112.751H1474.35V249.734Z"
        fill="themeInverseColor"
      />
      <path
        d="M1535.73 204.713L1552.03 203.383C1552.66 209.133 1554.56 214.67 1557.6 219.589C1561.01 224.119 1565.59 227.629 1570.85 229.736C1582.97 234.448 1596.35 234.779 1608.68 230.672C1613.26 229.155 1617.28 226.287 1620.21 222.446C1622.57 219.251 1623.84 215.385 1623.85 211.412C1623.99 207.462 1622.7 203.595 1620.21 200.526C1617.06 196.97 1613.01 194.332 1608.48 192.892C1600.76 190.237 1592.88 188.066 1584.89 186.39C1575.89 184.697 1567.13 181.936 1558.78 178.164C1553.09 175.43 1548.24 171.21 1544.74 165.948C1541.64 161.048 1540.05 155.344 1540.16 149.545C1540.1 142.766 1542.04 136.118 1545.73 130.434C1549.85 124.469 1555.67 119.886 1562.43 117.282C1578.62 111.302 1596.43 111.443 1612.52 117.676C1619.56 120.364 1625.6 125.165 1629.81 131.419C1633.76 137.578 1635.96 144.696 1636.17 152.008L1619.47 153.338C1619.29 149.7 1618.38 146.135 1616.79 142.858C1615.2 139.582 1612.96 136.662 1610.21 134.276C1603.61 129.48 1595.53 127.176 1587.4 127.774C1579.24 127.188 1571.11 129.187 1564.15 133.488C1561.98 135.119 1560.21 137.225 1558.98 139.645C1557.71 142.042 1557.05 144.715 1557.06 147.427C1556.83 149.887 1557.21 152.367 1558.18 154.639C1559.16 156.91 1560.68 158.902 1562.62 160.431C1570.67 165.295 1579.62 168.479 1588.93 169.79C1598.98 171.803 1608.87 174.602 1618.48 178.164C1625.21 181.022 1631.04 185.631 1635.38 191.512C1639.16 197.158 1641.1 203.832 1640.94 210.624C1640.75 217.451 1638.61 224.08 1634.79 229.736C1630.58 236.101 1624.58 241.073 1617.55 244.02C1609.57 247.57 1600.91 249.335 1592.18 249.192C1581.89 249.505 1571.63 247.746 1562.03 244.02C1554.22 240.813 1547.54 235.362 1542.82 228.357C1538.24 221.321 1535.77 213.112 1535.73 204.713Z"
        fill="themeInverseColor"
      />
      <path
        d="M1698.18 246.877V131.222H1654.78V115.755H1759.41V131.222H1715.71V246.877H1698.18Z"
        fill="themeInverseColor"
      />
      <path
        d="M1870.19 115.755H1887.62V192.103C1888.03 202.803 1886.48 213.486 1883.04 223.628C1879.9 231.631 1874.16 238.345 1866.74 242.691C1857.37 247.919 1846.72 250.426 1836 249.931C1825.52 250.372 1815.1 248.206 1805.66 243.626C1797.95 239.476 1791.96 232.72 1788.77 224.564C1785.07 214.021 1783.4 202.873 1783.84 191.709V115.361H1801.33V191.562C1800.88 200.017 1801.92 208.484 1804.38 216.584C1806.5 221.964 1810.44 226.428 1815.51 229.194C1821.43 232.286 1828.05 233.796 1834.72 233.578C1839.7 234.079 1844.73 233.559 1849.5 232.051C1854.22 230.538 1858.58 228.074 1862.31 224.81C1868.85 215.033 1871.71 203.251 1870.38 191.562L1870.19 115.755Z"
        fill="themeInverseColor"
      />
      <path
        d="M1914.72 246.878V115.755H1960.23C1968.14 115.507 1976.06 116.151 1983.82 117.676C1990.91 119.122 1997.52 122.342 2003.03 127.035C2010.41 132.887 2016.1 140.585 2019.53 149.349C2023.17 159.385 2024.92 170.005 2024.71 180.676C2024.9 189.647 2023.67 198.592 2021.06 207.176C2019.19 214.021 2016.14 220.487 2012.05 226.288C2008.84 230.912 2004.81 234.917 2000.18 238.11C1995.33 241.202 1990 243.466 1984.41 244.809C1977.4 246.262 1970.25 246.956 1963.09 246.878H1914.72ZM1932.2 231.411H1960.43C1967.41 231.64 1974.39 230.877 1981.16 229.145C1985.53 227.771 1989.53 225.411 1992.84 222.249C1997.39 217.575 2000.77 211.885 2002.69 205.649C2005.16 197.426 2006.32 188.866 2006.14 180.282C2006.77 169.363 2004.46 158.474 1999.44 148.758C1995.66 142.118 1989.79 136.921 1982.74 133.98C1975.45 131.868 1967.86 130.969 1960.28 131.32H1932.45L1932.2 231.411Z"
        fill="themeInverseColor"
      />
      <path
        d="M2050.66 246.877V115.755H2068.15V246.877H2050.66Z"
        fill="themeInverseColor"
      />
      <path
        d="M2093.67 182.942C2093.08 173.677 2094.34 164.389 2097.37 155.613C2100.4 146.838 2105.13 138.749 2111.3 131.813C2117.1 125.589 2124.16 120.667 2132.01 117.372C2139.85 114.078 2148.31 112.486 2156.81 112.701C2168.38 112.545 2179.78 115.505 2189.82 121.272C2199.35 127.302 2206.91 136.004 2211.54 146.294C2216.6 157.51 2219.22 169.675 2219.22 181.981C2219.22 194.287 2216.6 206.453 2211.54 217.668C2206.47 227.792 2198.42 236.127 2188.49 241.558C2178.64 246.886 2167.61 249.648 2156.42 249.586C2144.73 249.694 2133.25 246.575 2123.22 240.573C2113.52 234.75 2105.77 226.178 2100.96 215.944C2096.01 205.654 2093.51 194.358 2093.67 182.942ZM2111.5 182.942C2110.67 196.886 2115.36 210.596 2124.55 221.116C2128.68 225.509 2133.68 228.985 2139.24 231.319C2144.8 233.652 2150.79 234.791 2156.81 234.662C2162.9 234.863 2168.95 233.72 2174.55 231.312C2180.17 228.962 2185.21 225.415 2189.32 220.919C2198.36 209.963 2202.88 195.98 2201.98 181.809C2202.23 172.173 2200.4 162.595 2196.61 153.732C2193.27 146.121 2187.74 139.676 2180.72 135.22C2173.7 130.764 2165.52 128.497 2157.21 128.71C2145.34 128.558 2133.89 133.088 2125.34 141.319C2120.43 147.084 2116.71 153.766 2114.4 160.979C2112.09 168.192 2111.24 175.791 2111.89 183.336L2111.5 182.942Z"
        fill="themeInverseColor"
      />
      <defs>
        <linearGradient
          id="paint0_linear_8568_65782"
          x1="35.0838"
          y1="144.186"
          x2="175.516"
          y2="19.4672"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.22" stopColor="#80BB25" />
          <stop offset="0.32" stopColor="#86BE26" />
          <stop offset="0.46" stopColor="#99C52A" />
          <stop offset="0.63" stopColor="#B6D130" />
          <stop offset="0.81" stopColor="#E0E238" />
          <stop offset="0.85" stopColor="#EAE63A" />
        </linearGradient>
        <radialGradient
          id="paint1_radial_8568_65782"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(115.706 50.293) rotate(145.431) scale(44.1674)"
        >
          <stop stopColor="#C7E63A" stopOpacity="0.8" />
          <stop offset="0.32" stopColor="#D3E63A" stopOpacity="0.87" />
          <stop offset="1" stopColor="#EBE63A" />
        </radialGradient>
        <radialGradient
          id="paint2_radial_8568_65782"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(205.358 72.7844) rotate(130.1) scale(76.6287 76.6287)"
        >
          <stop stopColor="#C7E63A" stopOpacity="0.8" />
          <stop offset="0.32" stopColor="#D3E63A" stopOpacity="0.87" />
          <stop offset="1" stopColor="#EBE63A" />
        </radialGradient>
        <linearGradient
          id="paint3_linear_8568_65782"
          x1="180.588"
          y1="19.32"
          x2="108.131"
          y2="69.2666"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C7E63A" />
          <stop offset="1" stopColor="#EBE63A" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_8568_65782"
          x1="0.355998"
          y1="132.167"
          x2="252.306"
          y2="175.661"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#80BB25" />
          <stop offset="0.18" stopColor="#7DBB2B" />
          <stop offset="0.36" stopColor="#5BB65F" />
          <stop offset="0.45" stopColor="#4AB478" />
          <stop offset="0.72" stopColor="#31B19B" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_8568_65782"
          x1="107.197"
          y1="268.659"
          x2="242.555"
          y2="137.684"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.18" stopColor="#1E2C70" />
          <stop offset="0.19" stopColor="#1F2E71" />
          <stop offset="0.55" stopColor="#437DA3" />
          <stop offset="0.8" stopColor="#5AAEC2" />
          <stop offset="0.93" stopColor="#63C1CE" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_8568_65782"
          x1="188.474"
          y1="191.424"
          x2="243.396"
          y2="139.064"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.3" stopColor="#71C3D1" stopOpacity="0" />
          <stop offset="0.39" stopColor="#71C3D1" stopOpacity="0.02" />
          <stop offset="0.48" stopColor="#70C4D1" stopOpacity="0.07" />
          <stop offset="0.57" stopColor="#6FC5D1" stopOpacity="0.17" />
          <stop offset="0.66" stopColor="#6EC6D2" stopOpacity="0.3" />
          <stop offset="0.75" stopColor="#6CC7D2" stopOpacity="0.47" />
          <stop offset="0.84" stopColor="#6AC9D2" stopOpacity="0.68" />
          <stop offset="0.93" stopColor="#67CBD3" stopOpacity="0.92" />
          <stop offset="0.95" stopColor="#66CCD3" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_8568_65782"
          x1="111.826"
          y1="258.512"
          x2="174.037"
          y2="209.945"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.51" stopColor="#09175F" stopOpacity="0.03" />
          <stop offset="1" stopColor="#1D2B6F" />
        </linearGradient>
        <radialGradient
          id="paint8_radial_8568_65782"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(121.847 196.707) rotate(-0.316803) scale(76.9692)"
        >
          <stop offset="0.13" stopColor="#09175F" />
          <stop offset="0.52" stopColor="#0D1B62" />
          <stop offset="0.9" stopColor="#19276C" />
          <stop offset="1" stopColor="#1D2B6F" />
        </radialGradient>
        <linearGradient
          id="paint9_linear_8568_65782"
          x1="136.802"
          y1="224.229"
          x2="88.7763"
          y2="298.115"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.06" stopColor="#09175F" />
          <stop offset="0.52" stopColor="#122066" />
          <stop offset="0.9" stopColor="#1D2B6F" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
};

export default memo(StudioLogoIcon);
