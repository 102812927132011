import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';

import type { SxProps} from '@mui/material';
import { Typography } from '@mui/material';

import AboutFooter from './AboutFooter';
import CommonFooter from './CommonFooter';
import MobileMoreDialogFooter from './MobileMoreDialogFooter';
import SideMenuFooter from './SideMenuFooter';

export const getFooterLink = (
  footerLabel: string,
  locale: string | undefined,
) => {
  switch (footerLabel) {
    case 'termOfUse':
      return 'https://blog.camerafi.com/2019/12/camerafi-live-terms-of-use.html';
    case 'privacyPolicy':
      return 'https://blog.camerafi.com/2019/12/camerafi-live-privacy-policy.html';
    case 'appInquiry':
      return 'mailto:sales@vaultmicro.com';
    case 'businessInquiry':
      return 'mailto:sales@vaultmicro.com';
    case 'broadcastInquiry':
      return 'https://blog.camerafi.com/p/blog-page_18.html';
    case 'advertise':
      if (locale === 'ko')
        return 'https://blog.camerafi.com/p/blog-page_4.html';
      else
        return 'https://blog.camerafi.com/p/advertisement-on-camerafi-studio.html';
    case 'facebook':
      if (locale === 'ko') return 'https://www.facebook.com/camerafikor';
      else return 'https://www.facebook.com/groups/170216666130703';
    case 'naver':
      return 'https://cafe.naver.com/camerafilive';
    default:
      return '';
  }
};

const ONLY_KO_FOOTER_LABELS = ['broadcastInquiry'];

const useFooter = (footerLabel: string) => {
  const { locale } = useRouter();
  const { t } = useTranslation('common');

  const link = getFooterLink(footerLabel, locale);

  if (ONLY_KO_FOOTER_LABELS.includes(footerLabel) && locale !== 'ko')
    return null;

  return {
    link: link === '' ? null : link,
    text: t(`footer.${footerLabel}`),
  };
};

export const FooterText = ({
  footerLabel,
  sx,
}: {
  footerLabel: string;
  sx?: SxProps;
}) => {
  const footer = useFooter(footerLabel);

  if (!footer) return null;

  return (
    <Typography
      component={footer.link ? 'a' : 'label'}
      onClick={() => {
        if (footer.link) window.open(footer.link, '_blank');
      }}
      sx={{
        display: 'block',
        fontSize: '12px',
        fontWeight: '400',
        cursor: footer.link ? { sm: 'pointer' } : 'default',
        ...sx,
      }}
    >
      {footer.text}
    </Typography>
  );
};

const Footer = {
  Common: CommonFooter,
  MoreDialog: MobileMoreDialogFooter,
  About: AboutFooter,
  SideMenu: SideMenuFooter,
};

export default Footer;
