import { memo, useEffect, useState } from 'react';

import useTranslation from 'next-translate/useTranslation';
import Image from 'next/image';
import { useRouter } from 'next/router';

import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, Modal, Typography } from '@mui/material';
import { getCookie, setCookie } from 'cookies-next';

const REOPEN_INTERVAL_SEVEN_DAYS = 60 * 60 * 24 * 7;

const MobilePopupBanner = ({
  mobilePopupInfo,
}: {
  mobilePopupInfo: {
    cookieName: string;
    imageLink: string;
    imageWidth: number;
    imageHeight: number;
    onClick: () => void;
  };
}) => {
  const { locale } = useRouter();
  const { t } = useTranslation('common');

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const popupClosedCookie = getCookie(mobilePopupInfo.cookieName);

  const closePopupBanner = (expiresIn: number) => {
    setCookie(mobilePopupInfo.cookieName, true, { maxAge: expiresIn });
    setIsPopupOpen(false);
  };

  useEffect(() => {
    if (!popupClosedCookie) {
      setIsPopupOpen(true);
    } else setIsPopupOpen(false);
  }, [locale, popupClosedCookie]);

  useEffect(() => {
    const handleResize = () => {
      if (typeof window !== 'undefined' && window.innerHeight < 500)
        setIsPopupOpen(false);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={isPopupOpen}
      onClose={() => {
        closePopupBanner(0);
      }}
      closeAfterTransition
      disablePortal
      slotProps={{
        backdrop: {
          onClick: undefined,
          style: {
            backdropFilter: 'blur(1.5px)',
          },
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '80%',
          maxHeight: '80%',
          maxWidth: '400px',
          '&:focus-visible': { outline: 'none' },
        }}
      >
        <IconButton
          onClick={() => {
            closePopupBanner(0);
          }}
          sx={{
            position: 'absolute',
            top: '4px',
            right: '4px',
            zIndex: 10,
            '&:focus:hover': {
              backgroundColor: 'transparent',
            },
          }}
        >
          <CloseIcon sx={{ color: 'white' }} />
        </IconButton>

        <Box>
          <Image
            priority
            onClick={() => {
              mobilePopupInfo.onClick();
              closePopupBanner(0);
            }}
            src={mobilePopupInfo.imageLink}
            alt="popup"
            width={mobilePopupInfo.imageWidth}
            height={mobilePopupInfo.imageHeight}
            style={{ borderRadius: '8px' }}
          />
        </Box>

        <Box
          sx={{
            height: '40px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            mx: 'auto',
          }}
        >
          <Typography
            onClick={() => {
              closePopupBanner(REOPEN_INTERVAL_SEVEN_DAYS);
            }}
            variant="body2Bold"
            sx={{
              textDecoration: 'underline',
              color: 'white',
              p: '4px 16px',
            }}
          >
            {t('popup.doNotShowAWeek')}
          </Typography>
        </Box>
      </Box>
    </Modal>
  );
};

export default memo(MobilePopupBanner);
