import { useEffect, useRef, useState } from 'react';

import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';

import CloseIcon from '@mui/icons-material/Close';
import SearchHistoryIcon from '@mui/icons-material/HistoryRounded';
import SearchIcon from '@mui/icons-material/SearchRounded';
import {
  Autocomplete,
  Box,
  CircularProgress,
  IconButton,
  Paper,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { keepPreviousData, useMutation, useQuery } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';

import useScoreboardApi from 'api/hooks/useScoreboardApi';

import useActiveAuth from 'hooks/useActiveAuth';
import useQueryEvents from 'hooks/useQueryEvents';

import errorMessage from 'utils/errorMessage';

const SearchTextField = ({
  shape,
  updateSearchQuery,
  onSearchAction,
}: {
  shape: 'page' | 'banner' | 'mobile';
  updateSearchQuery?: (newSearchQuery: string) => void;
  onSearchAction?: () => void;
}) => {
  const { isProfileLoadedUser, user } = useActiveAuth();
  const { getSearchKeyword, postSearchKeyword, deleteSearchKeyword } =
    useScoreboardApi();
  const {
    breakpoints,
    palette: { mode },
  } = useTheme();
  const router = useRouter();
  const { t } = useTranslation('search');
  const isTablet = useMediaQuery(breakpoints.down('md'));

  const [isSearchLogOpen, setIsSearchLogOpen] = useState(true);
  const [isSearchError, setIsSearchError] = useState(false);
  const [searchInput, setSearchInput] = useState<string>(
    typeof router.query.q === 'string'
      ? decodeURIComponent(router.query.q)
      : '',
  );

  const searchInputRef = useRef<HTMLInputElement>(null);

  const isSearchPage = router.asPath.includes('/search');

  const {
    data: searchLogData,
    isLoading: searchLogDataIsInitialLoading,
    refetch: searchLogDataRefetch,
    error,
  } = useQuery({
    queryKey: ['searchLog', user?.id],
    queryFn: async () => await getSearchKeyword(),
    enabled: isProfileLoadedUser,
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5,
  });
  useQueryEvents(
    { error },
    {
      onError: (error) => {
        enqueueSnackbar(errorMessage(error), {
          variant: 'error',
        });
      },
    },
  );

  const addSearchLogMutation = useMutation({
    mutationFn: async (searchInput: string) =>
      await postSearchKeyword(searchInput),
    onSuccess: () => {
      searchLogDataRefetch();
    },
    onError: (error) => {
      enqueueSnackbar(errorMessage(error), {
        variant: 'error',
      });
    },
  });

  const deleteSearchLogMutation = useMutation({
    mutationFn: async (searchLogId: number) =>
      await deleteSearchKeyword(searchLogId),
    onSuccess: () => {
      searchLogDataRefetch();
    },
    onError: (error) => {
      enqueueSnackbar(errorMessage(error), {
        variant: 'error',
      });
    },
  });

  const handleSearchEvent = (inputValue: string) => {
    if (inputValue.length < 2) {
      setIsSearchError(true);
      return;
    }

    if (isSearchError) setIsSearchError(false);
    onSearchAction && onSearchAction();

    const encodedKeyword = encodeURIComponent(inputValue.replace(/\s+/g, ' '));

    if (!isSearchPage) {
      router.push(`/search?q=${encodedKeyword}`);
      if (isProfileLoadedUser) addSearchLogMutation.mutate(encodedKeyword);
      return;
    }

    updateSearchQuery && updateSearchQuery(encodedKeyword);
    router.replace(`/search?q=${encodedKeyword}`);
    if (isProfileLoadedUser) addSearchLogMutation.mutate(encodedKeyword);
  };

  useEffect(() => {
    searchInputRef.current?.focus();
    if (!router.query.q) setSearchInput('');
  }, [router.query.q]);

  const inputPropsStyle = {
    page: {
      style: {
        padding: '0 8px 0 24px',
        fontSize: isTablet ? '16px' : '20px',
        borderRadius: '30px',
        borderWidth: isTablet ? '2px' : '3px',
        borderStyle: 'solid',
        height: isTablet ? '46px' : '64px',
        borderImage: 'linear-gradient(115deg,#37C556 13.75%,#15B3B3 82.09%)',
        borderImageSlice: 1,
      },
      endAdornment: (
        <Box
          sx={{
            svg: {
              width: { sm: '24px', md: '28px' },
              height: { sm: '24px', md: '28px' },
            },
          }}
        >
          {searchInput ? (
            <IconButton
              size="small"
              onClick={() => {
                setSearchInput('');
              }}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
          <IconButton
            size="small"
            onClick={() => {
              handleSearchEvent(searchInput.trim());
              setSearchInput(searchInput.trim().replace(/\s+/g, ' '));
            }}
          >
            <SearchIcon sx={{ color: 'secondaryColor.2' }} />
          </IconButton>
        </Box>
      ),
    },
    banner: {
      style: {
        lineHeight: '40px',
        padding: '0 16px',
        borderBottom: '1px solid',
        borderColor: '#9393A2',
        borderRadius: 0,
        fontSize: '24px',
      },
      startAdornment: (
        <SearchIcon
          sx={{
            mr: 2,
            color: 'gray !important',
            width: '28px',
            height: '28px',
          }}
        />
      ),
      endAdornment: searchInput && (
        <IconButton
          onClick={() => {
            setSearchInput('');
          }}
        >
          <CloseIcon />
        </IconButton>
      ),
    },
    mobile: {
      style: {
        padding: '0 6px',
        height: '48px',
        borderBottom: '1px solid',
        borderColor: '#9393A2',
        borderRadius: 0,
      },
      endAdornment: (
        <Box>
          {searchInput ? (
            <IconButton
              size="small"
              onClick={() => {
                setSearchInput('');
              }}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
          <IconButton
            size="small"
            onClick={() => {
              handleSearchEvent(searchInput.trim());
              setSearchInput(searchInput.trim().replace(/\s+/g, ' '));
            }}
          >
            <SearchIcon />
          </IconButton>
        </Box>
      ),
    },
  };

  return (
    <Paper
      sx={{
        display: 'flex',
        width: shape === 'mobile' ? '100%' : '75%',
        maxWidth: shape === 'page' ? { sm: '468px', md: '880px' } : 'none',
        boxShadow: 'none',
        background: 'transparent',
        borderRadius: { sm: '30px' },
      }}
    >
      <Autocomplete
        freeSolo
        fullWidth
        value={searchInput}
        options={searchLogData || []}
        getOptionLabel={(searchLog) => {
          if (typeof searchLog === 'string') return searchLog;
          else return searchLog.keyword;
        }}
        PaperComponent={({ children }) => (
          <Paper
            sx={{
              display:
                isProfileLoadedUser && isSearchLogOpen ? 'block' : 'none',
              border: '2px solid',
              borderColor: 'background.default',
              backgroundColor:
                mode === 'dark' ? 'deprecatedGray' : 'deprecatedGray.95',
              boxShadow: 'none',
              borderRadius: '0 0 8px 8px',
            }}
          >
            {searchLogDataIsInitialLoading ? (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100px',
                }}
              >
                <CircularProgress />
              </Box>
            ) : searchLogData?.length === 0 ? (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100px',
                }}
              >
                {t('noSearchLog')}
              </Box>
            ) : (
              children
            )}
          </Paper>
        )}
        renderOption={(props, searchLog) => (
          <li
            {...props}
            onClick={(e) => {
              e.preventDefault();
            }}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '8px 16px',
              cursor: 'pointer',
            }}
          >
            <Box
              key={searchLog.id}
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                flex: 1,
              }}
              onClick={() => {
                setIsSearchLogOpen(false);
                setSearchInput(searchLog.keyword);
                handleSearchEvent(searchLog.keyword);
              }}
            >
              <SearchHistoryIcon />
              <Typography variant="body1">{searchLog.keyword}</Typography>
            </Box>
            <CloseIcon
              onClick={() => deleteSearchLogMutation.mutate(searchLog.id)}
            />
          </li>
        )}
        renderInput={(params) => (
          <TextField
            focused
            inputRef={searchInputRef}
            {...params}
            type="search"
            variant="outlined"
            placeholder={t('searchPlaceholder')}
            onChange={(e) => setSearchInput(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                const target = e.target as HTMLInputElement;
                handleSearchEvent(target.value.trim());
                setSearchInput(target.value.trim().replace(/\s+/g, ' '));
                target.blur();
              }
            }}
            helperText={isSearchError ? t('searchHelperText') : ''}
            onClick={() => {
              setIsSearchLogOpen(true);
            }}
            sx={{
              'input::-webkit-search-cancel-button': { display: 'none' },
              '.MuiOutlinedInput-notchedOutline': { border: 'none' },
            }}
            InputProps={{
              ...params.InputProps,
              ...inputPropsStyle[shape],
            }}
          />
        )}
      />
    </Paper>
  );
};

export default SearchTextField;
