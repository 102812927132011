import { useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, ListItemButton } from '@mui/material';
import { useSuspenseQuery } from '@tanstack/react-query';

import useChannelApi, {
  GET_MANAGED_CHANNEL_LIST_KEY,
} from 'api/hooks/useChannelApi';

import ChannelCard from 'components/atoms/ChannelCard';
import ChannelManagerQuitDialog from 'components/organisms/ChannelManagerQuitDialog';

import useActiveAuth from 'hooks/useActiveAuth';
import useAuth from 'hooks/useAuth';
import useQueryEvents from 'hooks/useQueryEvents';
import useToggle from 'hooks/useToggle';

import type { ManagedChannelOwnerType } from 'types/channel';

const ChannelList = ({
  closeProfileMenu,
  handleListItemShow,
}: {
  closeProfileMenu: () => void;
  handleListItemShow: (value: boolean) => void;
}) => {
  const { getManagedChannelList } = useChannelApi();
  const {
    user: { id, nickname: ownerNickname, photoURL },
    channel: { channelName },
  } = useAuth();
  const { changeActiveAccount, getIsLoginUser } = useActiveAuth();

  const {
    close: closeChannelManagerQuitDialog,
    isOpen: isChannelManagerQuitDialogOpen,
    open: openChannelManagerQuitDialog,
  } = useToggle();

  const [selectedChannelId, setSelectedChannelId] = useState<number>(NaN);
  const [managerNickname, setManagerNickname] = useState<string>('');

  const { data } = useSuspenseQuery({
    queryKey: GET_MANAGED_CHANNEL_LIST_KEY,
    queryFn: () => getManagedChannelList(),
    refetchOnWindowFocus: false,
  });
  useQueryEvents(
    { data },
    {
      onSuccess: (data) => handleListItemShow(data.length > 0),
    },
  );

  const myChannel: ManagedChannelOwnerType = {
    id: 0,
    owner: {
      channel: {
        channelName,
      },
      nickname: ownerNickname,
      profileUrl: photoURL,
      uid: id,
    },
    accepted: true,
    role: 'owner',
  };

  const myInfoAddedManagedChannelList: ManagedChannelOwnerType[] = [
    myChannel,
    ...(data ?? []),
  ];

  const btnClickHandler = async (channelInfo: ManagedChannelOwnerType) => {
    openChannelManagerQuitDialog();
    setSelectedChannelId(channelInfo.id);
    setManagerNickname(channelInfo.owner.nickname);
  };

  return (
    <>
      {myInfoAddedManagedChannelList.map((channelInfo) => (
        <Box
          key={channelInfo.owner.nickname}
          onClick={async () => {
            closeProfileMenu();
            await changeActiveAccount(channelInfo);
          }}
          sx={{
            position: 'relative',
          }}
        >
          <ListItemButton
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
          />
          <ChannelCard
            channelName={channelInfo.owner.channel.channelName}
            nickname={channelInfo.owner.nickname}
            trigger={
              !getIsLoginUser(channelInfo.owner.uid) && (
                <IconButton
                  sx={{
                    '& svg': {
                      marginLeft: '0 !important',
                    },
                  }}
                  onClick={async () => {
                    await btnClickHandler(channelInfo);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              )
            }
            profileUrl={channelInfo.owner.profileUrl}
            role={channelInfo.role}
          />
        </Box>
      ))}
      <ChannelManagerQuitDialog
        isOpen={
          !Number.isNaN(selectedChannelId) && isChannelManagerQuitDialogOpen
        }
        managerId={selectedChannelId}
        onCLose={closeChannelManagerQuitDialog}
        managerNickname={managerNickname}
      />
    </>
  );
};

export default ChannelList;
