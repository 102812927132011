import { useEffect, useState } from 'react';

import useTranslation from 'next-translate/useTranslation';

import {
  Box,
  Button,
  AppBar as MuiAppBar,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { getCookie, setCookie } from 'cookies-next';

import useAuthApi from 'api/hooks/useAuthApi';

import Link from 'components/atoms/Link';

import useActiveAuth from 'hooks/useActiveAuth';

const CookieConsent = () => {
  const { user, updateCookieAllowed } = useActiveAuth();
  const { updateCookieSetting } = useAuthApi();
  const theme = useTheme();
  const { t } = useTranslation('common');
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [showConsent, setShowConsent] = useState<boolean | undefined>(false);

  useEffect(() => {
    const isNotReady = user && user.country === '' && user.language === '';
    if (isNotReady) return;

    const isLoggedInUser = user && user.id;

    if (isLoggedInUser) {
      if (user.cookieAllowed !== undefined) {
        setCookie('localConsent', `${user?.cookieAllowed}`, {});
        setShowConsent(user.cookieAllowed);
        return;
      }

      const localConsentCookie = getCookie('localConsent');
      if (localConsentCookie === undefined) {
        setShowConsent(undefined);
      }
    }
  }, [user]);

  const acceptCookie = async () => {
    setShowConsent(true);
    updateCookieAllowed(true);
    await updateCookieSetting(user.id, true);
  };

  const declineCookie = async () => {
    setShowConsent(false);
    updateCookieAllowed(false);
    await updateCookieSetting(user.id, false);
  };

  return showConsent === undefined ? (
    <>
      <Toolbar sx={{ height: 'auto' }} />
      <MuiAppBar
        component="div"
        sx={{
          backgroundColor: 'deprecatedGray.30',
          bottom: 0,
          boxShadow: 'none',
          height: 'auto',
          top: 'auto',
          paddingY: '10px',
          zIndex: 1302,
        }}
      >
        <Toolbar
          sx={{
            m: 'auto',
            maxWidth: '1200px',
            width: '100%',
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
              color: 'white',
            }}
          >
            <Typography variant="h4" sx={{ my: 1 }}>
              {t('cookieAgree.title')}
            </Typography>
            <Typography sx={{ mb: 1 }}>
              {t('cookieAgree.body1')}
              <Link
                href="https://blog.camerafi.com/2019/12/camerafi-live-terms-of-use.html"
                target="_blank"
                sx={{ color: 'inherit', textDecorationColor: 'inherit' }}
              >
                {t('cookieAgree.link')}
              </Link>
              {t('cookieAgree.body2')}
            </Typography>
          </Box>
          <Box>
            <Button
              color="inherit"
              variant="contained"
              onClick={() => acceptCookie()}
              sx={{
                backgroundColor: 'white',
                borderRadius: '20px',
                color: 'deprecatedGray.9',
                ml: '5px',
                p: '8px 24px',
                whiteSpace: 'nowrap',
                mb: { xs: 0, sm: 2 },
              }}
            >
              {t('cookieAgree.accept')}
            </Button>
            <Button
              color="inherit"
              variant="contained"
              onClick={() => declineCookie()}
              sx={{
                backgroundColor: 'white',
                borderRadius: '20px',
                color: 'deprecatedGray.9',
                ml: '5px',
                p: '8px 24px',
                whiteSpace: 'nowrap',
              }}
            >
              {t('cookieAgree.decline')}
            </Button>
          </Box>
        </Toolbar>
      </MuiAppBar>
    </>
  ) : null;
};

export default CookieConsent;
