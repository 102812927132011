import type { BoxProps, SxProps } from '@mui/material';
import { Box, List } from '@mui/material';
import { ListItem, ListItemButton } from '@mui/material';

import { FooterText } from './Footer';

const SideMenuFooterButton = ({
  footerLabel,
  sx,
}: {
  footerLabel: string;
  sx?: SxProps;
}) => (
  <ListItem disablePadding sx={{ width: 'fit-content', flexShrink: 0 }}>
    <ListItemButton sx={{ p: '0 4px', mr: 1 }}>
      <FooterText
        footerLabel={footerLabel}
        sx={{ color: '#9393a2', fontSize: '14px', ...sx }}
      />
    </ListItemButton>
  </ListItem>
);

const SideMenuFooter = ({ ...rest }: BoxProps) => (
  <Box id="gtm-footer-area" component="footer" {...rest}>
    <List sx={{ display: 'flex', flexWrap: 'wrap', padding: '0 0 8px' }}>
      <SideMenuFooterButton footerLabel="termOfUse" />
      <SideMenuFooterButton
        footerLabel="privacyPolicy"
        sx={{ fontWeight: 700 }}
      />
      <SideMenuFooterButton footerLabel="appInquiry" />
      <SideMenuFooterButton footerLabel="businessInquiry" />
      <SideMenuFooterButton footerLabel="advertise" />
      <SideMenuFooterButton footerLabel="broadcastInquiry" />
    </List>

    <List sx={{ p: 0 }}>
      <SideMenuFooterButton footerLabel="copyright" sx={{ color: '#78788a' }} />
    </List>
  </Box>
);

export default SideMenuFooter;
