import { useState } from 'react';

import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';

import BrightnessIcon from '@mui/icons-material/Brightness4';
import CheckIcon from '@mui/icons-material/Check';
import MembershipIcon from '@mui/icons-material/CreditCard';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LogoutIcon from '@mui/icons-material/Logout';
import MyChannelIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import type {
  ListItemTextProps} from '@mui/material';
import {
  Avatar,
  Box,
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon as MuiListItemIcon,
  ListItemText as MuiListItemText,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { setCookie } from 'cookies-next';

import { Translate } from 'components/atoms/Icons';
import { NextLinkComposed } from 'components/atoms/Link';
import { Divider } from 'components/atoms/Spacing';

import useActiveAuth from 'hooks/useActiveAuth';
import useDarkMode from 'hooks/useDarkMode';

import { getLanguageNameFromCodeByIntl } from 'utils/internationalization';

import OtherChannelListItem from './OtherChannelListItem';

const ListItemText = (props: ListItemTextProps) => (
  <MuiListItemText primaryTypographyProps={{ variant: 'body1' }} {...props} />
);

const ListItemIcon = styled(MuiListItemIcon)`
  width: 56px;
`;

const ProfileMenu = ({
  onClose,
  handleSignOut,
}: {
  onClose: () => void;
  handleSignOut: () => void;
}) => {
  const { channel } = useActiveAuth();
  const {
    autoModeActive,
    darkModeActive,
    switchToAutoMode,
    switchToDarkMode,
    switchToLightMode,
  } = useDarkMode();
  const router = useRouter();
  const { t } = useTranslation('common');
  const {
    breakpoints,
    palette: { mode },
  } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('sm'));

  const [isThemeMenuOpen, setThemeMenuOpen] = useState(false);

  const handleClose = () => {
    onClose();
    setThemeMenuOpen(false);
  };

  const switchLanguage = () => {
    setCookie('locale', router.locale === 'en' ? 'ko' : 'en', {
      maxAge: 60 * 60 * 24 * 365 * 1000,
    });
    router.push(
      { pathname: router.pathname, query: router.query },
      router.asPath,
      { locale: router.locale === 'en' ? 'ko' : 'en' },
    );
  };

  const ProfileMenuDivider = () => (
    <Divider
      sx={{
        borderColor:
          mode === 'dark'
            ? { xs: '#21262d', lg: 'deprecatedGray.30' }
            : '#f0f0f0',
        my: 1.5,
      }}
    />
  );
  return (
    <List
      id="gtm-profile-area"
      aria-labelledby="nav-list"
      component="nav"
      sx={{ p: '12px 8px', '& svg': { ml: 1 } }}
    >
      {!isMobile ? (
        <>
          <ListItem>
            <Avatar
              alt={channel?.channelName ?? 'channel-image'}
              src={channel?.profileUrl ?? ''}
              sx={{ mr: 2, '& svg': { ml: 0 } }}
            />
            <Box>
              <Typography
                variant="h5"
                sx={{
                  display: '-webkit-box',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  WebkitLineClamp: 1,
                  WebkitBoxOrient: 'vertical',
                  overflowWrap: 'anywhere',
                }}
              >
                {channel?.channelName ?? 'Unknown Channel'}
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  display: '-webkit-box',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  WebkitLineClamp: 1,
                  WebkitBoxOrient: 'vertical',
                  overflowWrap: 'anywhere',
                  color: 'gray.700',
                }}
              >
                {channel?.nickname ? `@${channel?.nickname}` : ''}
              </Typography>
            </Box>
          </ListItem>
        </>
      ) : null}
      <OtherChannelListItem closeProfileMenu={onClose} />

      <ListItemButton
        className="gtm-channel-detail-btn"
        component={NextLinkComposed}
        to={`/channel/@${channel.nickname}`}
        onClick={handleClose}
      >
        <ListItemIcon sx={{ minWidth: 48 }}>
          <MyChannelIcon />
        </ListItemIcon>
        <ListItemText primary={t(`menu.myChannel`)} />
      </ListItemButton>

      <ListItemButton
        className="gtm-membership-btn"
        component={NextLinkComposed}
        to="/membership"
        onClick={handleClose}
      >
        <ListItemIcon sx={{ minWidth: 48 }}>
          <MembershipIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary={t('membership')} />
      </ListItemButton>
      <ProfileMenuDivider />
      <ListItemButton
        className="gtm-setting-btn"
        component={NextLinkComposed}
        to="/settings"
        onClick={handleClose}
      >
        <ListItemIcon>
          <SettingsIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary={t('settings')} />
      </ListItemButton>

      <ListItemButton
        className="gtm-language-btn"
        onClick={() => {
          switchLanguage();
          handleClose();
        }}
      >
        <ListItemIcon>
          <Translate fontSize="small" />
        </ListItemIcon>
        <ListItemText
          primary={`${
            isMobile ? '' : `${t('language')}:`
          } ${getLanguageNameFromCodeByIntl(
            router.locale ?? 'en',
            router.locale,
          )}`}
        />
      </ListItemButton>

      <ListItemButton
        className="gtm-design-btn"
        onClick={() => setThemeMenuOpen(!isThemeMenuOpen)}
        sx={
          isThemeMenuOpen
            ? {
                backgroundColor: 'action.hover',
                borderRadius: '8px 8px 0px 0px',
              }
            : undefined
        }
      >
        <ListItemIcon>
          <BrightnessIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText
          primary={`${isMobile ? '' : `${t('appearance')}:`} ${
            autoModeActive
              ? t('autoTheme')
              : darkModeActive
                ? t('darkTheme')
                : t('lightTheme')
          }`}
        />
        {isThemeMenuOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItemButton>
      <Collapse in={isThemeMenuOpen} timeout="auto" unmountOnExit>
        <List
          component="div"
          disablePadding
          sx={({ palette }) => ({
            backgroundColor: palette.action.hover,
            borderRadius: '0px 0px 8px 8px',
          })}
        >
          <ListItemButton
            onClick={() => {
              switchToAutoMode();
            }}
          >
            <ListItemIcon>
              {autoModeActive ? <CheckIcon fontSize="small" /> : null}
            </ListItemIcon>
            <ListItemText primary={t('autoTheme')} />
          </ListItemButton>
          <ListItemButton
            onClick={() => {
              switchToDarkMode();
            }}
          >
            <ListItemIcon>
              {darkModeActive && !autoModeActive ? (
                <CheckIcon fontSize="small" />
              ) : null}
            </ListItemIcon>
            <ListItemText primary={t('darkTheme')} />
          </ListItemButton>
          <ListItemButton
            onClick={() => {
              switchToLightMode();
            }}
          >
            <ListItemIcon>
              {!darkModeActive && !autoModeActive ? (
                <CheckIcon fontSize="small" />
              ) : null}
            </ListItemIcon>
            <ListItemText primary={t('lightTheme')} />
          </ListItemButton>
        </List>
      </Collapse>
      <ProfileMenuDivider />
      <ListItemButton
        className="gtm-logout-btn"
        key="signout"
        onClick={handleSignOut}
      >
        <ListItemIcon>
          <LogoutIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary={t('signout')} />
      </ListItemButton>
    </List>
  );
};

export default ProfileMenu;
