import { useCallback } from 'react';

import useAxios from 'hooks/useAxios';

import type { NotificationData} from 'types/notification';
import { Tab } from 'types/notification';

const useNotificationApi = () => {
  const { studioAuthAxiosV1 } = useAxios();

  const getNotifications = useCallback(
    async (tab: Tab, page: number, limit: number) => {
      const messageTypeList =
        tab === Tab.Notifications
          ? 'notificationMessageTypes=my_activity&notificationMessageTypes=social&notificationMessageTypes=system'
          : 'notificationMessageTypes=marketing';

      return studioAuthAxiosV1<{
        prev?: number;
        next?: number;
        pageCount: number;
        totalCount: number;
        notificationHistories: NotificationData[];
      }>({
        method: 'GET',
        url: `/notification/history?${messageTypeList}`,
        params: {
          days: 14,
          page,
          limit,
        },
      });
    },
    [studioAuthAxiosV1],
  );

  const updateNotificationHistoryIdList = useCallback(
    async (idList: number[]) => {
      if (idList.length > 0) {
        await studioAuthAxiosV1({
          method: 'PATCH',
          url: '/notification/history',
          data: { updateNotificationHistoryIdList: idList },
        });
      }
    },
    [studioAuthAxiosV1],
  );

  return {
    getNotifications,
    updateNotificationHistoryIdList,
  };
};

export default useNotificationApi;
