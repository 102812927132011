export const notificationTimeFormatter = (time: string) => {
  const now = new Date();
  const yesterday = new Date();
  const createdAt = new Date(time);

  const second = 1000;
  const minute = second * 60;
  const hour = minute * 60;

  const timeDiff = now.getTime() - createdAt.getTime();

  if (timeDiff < minute) {
    return {
      type: 'now',
    };
  } else if (timeDiff < hour) {
    const minutes = Math.floor(timeDiff / minute);
    return {
      type: 'minute',
      data: minutes,
    };
  } else if (timeDiff < hour * 24) {
    // https://bobbyhadz.com/blog/javascript-check-if-date-is-today
    yesterday.setDate(yesterday.getDate() - 1);
    if (yesterday.toDateString() === createdAt.toDateString()) {
      return {
        type: 'yesterday',
      };
    } else {
      const hours = Math.floor(timeDiff / hour);
      return {
        type: 'hour',
        data: hours,
      };
    }
  } else {
    const days = Math.floor(timeDiff / hour / 24);
    return {
      type: 'day',
      data: days,
    };
  }
};
