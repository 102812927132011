import { useRouter } from 'next/router';

import { Box } from '@mui/material';

import useActiveAuth from 'hooks/useActiveAuth';

import CustomAdCard from './CustomAdCard';

const WebSideAdCard = () => {
  const { route } = useRouter();
  const { isSubscribedUser, isProfileLoadProcessComplete } = useActiveAuth();

  const isNotAllowPageList = ['/about', '/membership', '/404'];

  const isWebSideAdCardVisible =
    !isNotAllowPageList.includes(route) &&
    isProfileLoadProcessComplete &&
    !isSubscribedUser;

  return isWebSideAdCardVisible ? (
    <Box
      sx={{
        display: 'none',
        position: 'absolute',
        top: 0,
        right: '-230px',

        '@media (min-width: 1700px)': {
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
        },
      }}
    >
      <CustomAdCard
        adId="모든_우측사이드_카드_웹"
        width="200px"
        height="600px"
      />
    </Box>
  ) : null;
};

export default WebSideAdCard;
