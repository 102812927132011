import { useEffect, useState } from 'react';

import useTranslation from 'next-translate/useTranslation';

import {
  Box,
  Button,
  AppBar as MuiAppBar,
  Toolbar,
  Typography,
} from '@mui/material';

import useActiveAuth from 'hooks/useActiveAuth';
import useCheckDevice from 'hooks/useCheckDevice';

const LoginBanner = ({
  isNavigationBarVisible,
}: {
  isNavigationBarVisible: boolean;
}) => {
  const { signIn } = useActiveAuth();
  const { t } = useTranslation('common');
  const { isMobile, isTablet } = useCheckDevice();

  const [showBottomBar, setShowBottomBar] = useState(false);

  useEffect(() => {
    const bottomBarTimer = setTimeout(() => {
      setShowBottomBar(true);
    }, 15000);
    return () => clearTimeout(bottomBarTimer);
  }, []);

  useEffect(() => {
    const getScrollPercent = () =>
      ((document.documentElement.scrollTop || document.body.scrollTop) /
        ((document.documentElement.scrollHeight || document.body.scrollHeight) -
          document.documentElement.clientHeight)) *
      100;
    const scrollInterval = setInterval(() => {
      if (getScrollPercent() > 50) {
        setShowBottomBar(true);
        clearInterval(scrollInterval);
      }
    }, 1000);
    return () => clearInterval(scrollInterval);
  }, []);

  return showBottomBar ? (
    <>
      <Toolbar sx={{ height: 88 }} />
      <MuiAppBar
        component="div"
        sx={{
          zIndex: 100,
          bgcolor: '#19191d',
          bottom: { xs: isNavigationBarVisible ? '60px' : 0, sm: 0 },
          boxShadow: 'none',
          height: { sm: '88px' },
          lineHeight: { xs: '48px', sm: '88px' },
          top: 'auto',
        }}
      >
        <Toolbar sx={{ m: 'auto', maxWidth: '1200px', width: '100%' }}>
          <Typography
            sx={{
              color: 'deprecatedGray.90',
              display: '-webkit-box',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              WebkitLineClamp: 1,
              WebkitBoxOrient: 'vertical',
              overflowWrap: 'anywhere',
              wordBreak: 'break-word',
            }}
            variant={isMobile ? 'body2Bold' : isTablet ? 'body1Bold' : 'h4Bold'}
          >
            {t('bottomBar.main')}
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Button
            color="inherit"
            onClick={signIn}
            sx={{
              background:
                'linear-gradient(115deg,#37C556 13.75%,#15B3B3 82.09%)',
              borderRadius: '8px',
              color: 'white',
              ml: '5px',
              p: { xs: '6px 16px', sm: '8px 24px' },
              whiteSpace: 'nowrap',
              width: { xs: '66px', sm: '90px' },
              height: { xs: '32px', sm: '40px' },
            }}
            variant="contained"
          >
            <Typography
              variant={isMobile ? 'body2Bold' : 'body1Bold'}
              sx={{ fontWeight: 900 }}
            >
              {t('sign in')}
            </Typography>
          </Button>
        </Toolbar>
      </MuiAppBar>
    </>
  ) : null;
};

export default LoginBanner;
