import type { ReactNode } from 'react';

import useActiveAuth from 'hooks/useActiveAuth';

interface ComponentGuardProps {
  children: ReactNode;
  nonLoginFallback?: ReactNode; // 비로그인 유저에게 보여줄 컴포넌트
  loadingFallback?: ReactNode; // 로그인한 유저인지 확인 중일 때 보여줄 컴포넌트
  owner?: boolean; // 채널 소유자에게만 보여줘야 하는 경우 true
  subscribed?: boolean; // 구독자에게만 보여줘야 하는 경우 true
}

const ComponentGuard = ({
  children,
  nonLoginFallback = null,
  loadingFallback = null,
  owner = false,
  subscribed = false,
}: ComponentGuardProps) => {
  const { isAnonymousUser, role, isFirebaseAuthSuccess, isSubscribedUser } =
    useActiveAuth();

  if (isAnonymousUser) return <>{nonLoginFallback}</>;

  if (
    isFirebaseAuthSuccess === false ||
    (owner && role !== 'owner') ||
    (subscribed && isSubscribedUser)
  ) {
    return <>{loadingFallback}</>;
  }

  return <>{children}</>;
};

export default ComponentGuard;
