import { useEffect, useState } from 'react';

import { useRouter } from 'next/router';

import { Box, Typography } from '@mui/material';

const LineBanner = ({
  lineBannerInfo,
}: {
  lineBannerInfo: Record<
    string,
    {
      text: string;
      textColor: string;
      background: string;
      onClick: () => void;
    }[]
  >;
}) => {
  const { route, locale } = useRouter();

  const [initialized, setInitialized] = useState(false);
  const [lineBannerIndex, setLineBannerIndex] = useState(0);

  const lineBannerTextSetting = {
    background: lineBannerInfo[locale || 'en'][lineBannerIndex]?.background,
    color: lineBannerInfo[locale || 'en'][lineBannerIndex]?.textColor,
    lineHeight: '40px',
    textAlign: 'center',
  };

  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      return;
    }

    setLineBannerIndex(
      (prev) => (prev + 1) % lineBannerInfo[locale || 'en'].length,
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route, locale]);

  return (
    <Box
      id="gtm-top-banner-area"
      onClick={() => {
        lineBannerInfo[locale || 'en'][lineBannerIndex]?.onClick();
      }}
      sx={{
        cursor: 'pointer',
        width: '100%',
        transition: 'background-color 0.5s',

        ...lineBannerTextSetting,
      }}
    >
      <Typography
        variant="subtitle1Bold"
        component="p"
        sx={{
          lineHeight: '40px',
          display: '-webkit-box',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          WebkitLineClamp: 1,
          WebkitBoxOrient: 'vertical',
          overflowWrap: 'anywhere',
        }}
      >
        {lineBannerInfo[locale || 'en'][lineBannerIndex]?.text}
      </Typography>
    </Box>
  );
};

export default LineBanner;
