import { useCallback } from 'react';

import useAxios from 'hooks/useAxios';

import type { ChannelPermissionInvitedData } from 'types/channelPermissionInvited';

const useChannelPermissionInvitedApi = () => {
  const { studioAuthAxiosV1 } = useAxios();

  const getChannelPermissionInvited = useCallback(async () => {
    return studioAuthAxiosV1<ChannelPermissionInvitedData[]>({
      method: 'GET',
      url: '/channel/permission/invited',
    });
  }, [studioAuthAxiosV1]);

  const patchChannelPermissionInvited = useCallback(
    async (id: number, accepted: boolean) => {
      await studioAuthAxiosV1({
        method: 'PATCH',
        url: '/channel/permission/invited',
        data: { id, response: accepted },
      });
    },
    [studioAuthAxiosV1],
  );

  return {
    getChannelPermissionInvited,
    patchChannelPermissionInvited,
  };
};

export default useChannelPermissionInvitedApi;
