import { useState } from 'react';

import useTranslation from 'next-translate/useTranslation';

import BrightnessIcon from '@mui/icons-material/Brightness4';
import CheckIcon from '@mui/icons-material/Check';
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';

import MenuItemButton from 'components/atoms/MenuItemButton';

import useCheckDevice from 'hooks/useCheckDevice';
import useDarkMode from 'hooks/useDarkMode';

const ThemeChangeButton = () => {
  const { t } = useTranslation('common');
  const {
    darkModeActive,
    switchToDarkMode,
    switchToLightMode,
    switchToAutoMode,
    autoModeActive,
  } = useDarkMode();
  const { isMobile } = useCheckDevice();

  const [isThemeMenuOpen, setThemeMenuOpen] = useState(false);

  return (
    <>
      <MenuItemButton
        className="gtm-desgin-btn"
        buttonText={`${isMobile ? '' : `${t('appearance')}:`} ${
          autoModeActive
            ? t('autoTheme')
            : darkModeActive
              ? t('darkTheme')
              : t('lightTheme')
        }`}
        icon={<BrightnessIcon fontSize="small" />}
        onClick={() => setThemeMenuOpen(!isThemeMenuOpen)}
        sx={
          isThemeMenuOpen
            ? {
                backgroundColor: 'action.hover',
                borderRadius: '8px 8px 0px 0px',
              }
            : undefined
        }
      />

      <Collapse in={isThemeMenuOpen} timeout="auto" unmountOnExit>
        <List
          component="div"
          disablePadding
          sx={({ palette }) => ({
            backgroundColor: palette.action.hover,
            borderRadius: '0px 0px 8px 8px',
          })}
        >
          <ListItemButton
            onClick={() => {
              switchToAutoMode();
              setThemeMenuOpen(false);
            }}
          >
            <ListItemIcon>
              {autoModeActive ? <CheckIcon fontSize="small" /> : null}
            </ListItemIcon>
            <ListItemText primary={t('autoTheme')} />
          </ListItemButton>
          <ListItemButton
            onClick={() => {
              switchToDarkMode();
              setThemeMenuOpen(false);
            }}
          >
            <ListItemIcon>
              {darkModeActive && !autoModeActive ? (
                <CheckIcon fontSize="small" />
              ) : null}
            </ListItemIcon>
            <ListItemText primary={t('darkTheme')} />
          </ListItemButton>
          <ListItemButton
            onClick={() => {
              switchToLightMode();
              setThemeMenuOpen(false);
            }}
          >
            <ListItemIcon>
              {!darkModeActive && !autoModeActive ? (
                <CheckIcon fontSize="small" />
              ) : null}
            </ListItemIcon>
            <ListItemText primary={t('lightTheme')} />
          </ListItemButton>
        </List>
      </Collapse>
    </>
  );
};
export default ThemeChangeButton;
