import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';

import { Box, Divider, Grid } from '@mui/material';

import StudioLogoIcon from 'components/atoms/Icons/StudioLogoIcon';
import {
  FooterCategory,
  FooterText,
  FooterTitleText,
} from 'components/organisms/AboutPage/AboutPage.style';

import { getFooterLink } from './Footer';

const AboutFooter = () => {
  const { t: footer } = useTranslation('common');
  const t = (text: string) => footer(`footer.${text}`);
  const router = useRouter();

  const footerLink = (id: string) => getFooterLink(id, router.locale);

  return (
    <Box
      id="gtm-footer-area"
      component="footer"
      sx={{
        height: { xs: '350px', sm: '505px', md: '380px' },
        bgcolor: 'white',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          gap: { xs: '20px', md: '0px' },
          p: { xs: '24px', sm: '40px' },
        }}
      >
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            color: 'black',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', mt: 1.5, mb: 0.5 }}>
            <StudioLogoIcon />
          </Box>
          <FooterCategory>{t('category')}</FooterCategory>
        </Box>
        <Box sx={{ flex: 1, display: { xs: 'none', sm: 'block' } }}>
          <Grid
            container
            sx={{
              display: 'flex',
              justifyContent: 'space-around',
              color: '#9393A2',
              fontSize: '14px',
              fontWeight: 600,
              mb: '16px',
            }}
          >
            <Grid item sm={3}>
              <FooterTitleText>{t('introduction')}</FooterTitleText>
            </Grid>
            <Grid item sm={3}>
              <FooterTitleText>{t('match')}</FooterTitleText>
            </Grid>
            <Grid item sm={3}>
              <FooterTitleText>{t('event')}</FooterTitleText>
            </Grid>
            <Grid item sm={3}>
              <FooterTitleText>{t('support')}</FooterTitleText>
            </Grid>
          </Grid>

          <Grid
            container
            rowGap={1}
            sx={{
              display: 'flex',
              justifyContent: 'space-around',
              color: '#9393A2',
              fontSize: '14px',
            }}
          >
            <Grid item sm={3}>
              <FooterText onClick={() => router.push('/')}>
                {t('home')}
              </FooterText>
            </Grid>
            <Grid item sm={3}>
              <FooterText onClick={() => router.push('/')}>
                {t('matchCreate')}
              </FooterText>
            </Grid>
            <Grid item sm={3}>
              <FooterText onClick={() => router.push('/')}>
                {t('eventCreate')}
              </FooterText>
            </Grid>
            <Grid item sm={3}>
              <FooterText
                onClick={() => window.open(footerLink('naver'), '_blank')}
              >
                {t('naver')}
              </FooterText>
            </Grid>

            <Grid item sm={3} />
            <Grid item sm={3}>
              <FooterText onClick={() => router.push('/')}>
                {t('captionCreate')}
              </FooterText>
            </Grid>
            <Grid item sm={3}>
              <FooterText onClick={() => router.push('/event')}>
                {t('eventView')}
              </FooterText>
            </Grid>
            <Grid item sm={3}>
              <FooterText
                onClick={() => window.open(footerLink('facebook'), '_blank')}
              >
                {t('facebook')}
              </FooterText>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Divider sx={{ borderColor: 'gray.200' }} />

      <Box
        sx={{
          p: { xs: '24px', sm: '40px' },
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          gap: { xs: '20px', md: '0px' },
        }}
      >
        <Box
          sx={{
            flex: 2,
            display: 'flex',
            flexDirection: 'column',
            gap: 4,

            label: { color: '#aeaeba' },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              columnGap: { xs: '16px', sm: '52px' },
              label: { cursor: 'pointer' },
              flexWrap: 'wrap',
            }}
          >
            <FooterTitleText
              onClick={() => window.open(footerLink('termOfUse'), '_blank')}
            >
              {t('termOfUse')}
            </FooterTitleText>
            <FooterTitleText
              sx={{ fontWeight: 700 }}
              onClick={() => window.open(footerLink('privacyPolicy'), '_blank')}
            >
              {t('privacyPolicy')}
            </FooterTitleText>
            <FooterTitleText
              onClick={() => window.open(footerLink('appInquiry'), '_blank')}
            >
              {t('appInquiry')}
            </FooterTitleText>
            <FooterTitleText
              onClick={() =>
                window.open(footerLink('businessInquiry'), '_blank')
              }
            >
              {t('businessInquiry')}
            </FooterTitleText>
            <FooterTitleText
              onClick={() => {
                window.open(footerLink('advertise'), '_blank');
              }}
            >
              {t('advertise')}
            </FooterTitleText>
            <FooterTitleText
              onClick={() => {
                window.open(footerLink('broadcastInquiry'), '_blank');
              }}
            >
              {t('broadcastInquiry')}
            </FooterTitleText>
          </Box>
          <Box sx={{ textAlign: 'start' }}>
            <FooterTitleText>{t('company')}</FooterTitleText>
            <FooterTitleText>{t('address')}</FooterTitleText>
          </Box>
        </Box>
        <Box
          sx={{
            flex: 1,
            textAlign: { xs: 'left', md: 'center' },
            color: '#aeaeba',
            fontWeight: 400,
            fontSize: '12px',
          }}
        >
          {`© ${new Date().getFullYear()} - Vault Micro, Inc.`}
        </Box>
      </Box>
    </Box>
  );
};

export default AboutFooter;
