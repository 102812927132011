import { Box } from '@mui/material';

const PlusBadge = ({
  size,
  backgroundColor,
}: {
  size: 'small' | 'medium';
  backgroundColor: string;
}) => (
  <Box
    sx={{
      ...(size === 'small'
        ? { width: '40px', height: '18px' }
        : { width: '46px', height: '21px' }),

      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: '2px solid transparent',
      borderRadius: '4px',
      backgroundImage: `linear-gradient(${backgroundColor}, ${backgroundColor}), linear-gradient(115deg,#37C556 13.75%,#15B3B3 82.09%)`,
      bgcolor: 'gray.50',
      borderWidth: '2px',
      borderColor: 'none',
      backgroundOrigin: 'border-box',
      backgroundClip: 'content-box, border-box',
    }}
  >
    <Box
      sx={{
        background: 'linear-gradient(115deg,#37C556 13.75%,#15B3B3 82.09%)',
        width: 'calc(100% - 2px)',
        height: 'calc(100% - 2px)',
        borderRadius: '1px',
        fontWeight: 700,
        lineHeight: '1.4',
        color: backgroundColor,
        ...(size === 'small' ? { fontSize: '9px' } : { fontSize: '10px' }),
      }}
    >
      PLUS
    </Box>
  </Box>
);

export default PlusBadge;
