export const getLanguageNameFromCodeByIntl = (
  languageCode: string,
  locale?: string,
) => {
  try {
    return new Intl.DisplayNames([locale ?? 'en'], { type: 'language' }).of(
      languageCode,
    );
  } catch (error) {
    console.error(error);
    return '';
  }
};
