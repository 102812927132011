import type { Translate } from 'next-translate';

import type {
  NotificationEvent} from 'types/notification';
import {
  NotificationMessageType,
  StudioEventType,
} from 'types/notification';

export const notificationContent = (
  item: Pick<
    NotificationEvent,
    | 'studioEventType'
    | 'messageType'
    | 'channelName'
    | 'matchTitle'
    | 'eventTitle'
    | 'title'
    | 'body'
  >,
  t: Translate,
) => {
  if (
    item.messageType === NotificationMessageType.SYSTEM ||
    item.messageType === NotificationMessageType.MARKETING
  ) {
    return {
      title: {
        start: item.title,
      },
      description: {
        start: item.body,
      },
    };
  } else {
    switch (item.studioEventType) {
      case StudioEventType.SCOREBOARD_SCHEDULE:
        return {
          title: {
            start: t('notificationMenu.content.scoreboard_schedule.titleStart'),
          },
          description: {
            bold: item.matchTitle,
          },
        };
      case StudioEventType.SCOREBOARD_REMIND:
        return {
          title: {
            start: t('notificationMenu.content.scoreboard_remind.titleStart'),
          },
          description: {
            bold: item.matchTitle,
          },
        };
      case StudioEventType.EVENT_PROGRESS:
        return {
          title: {
            start: t('notificationMenu.content.event_progress.titleStart'),
          },
          description: {
            bold: item.eventTitle,
          },
        };
      case StudioEventType.EVENT_END:
        return {
          title: {
            start: t('notificationMenu.content.event_end.titleStart'),
          },
          description: {
            bold: item.eventTitle,
          },
        };
      case StudioEventType.SCOREBOARD_CREATED:
        return {
          title: {
            bold: item.channelName,
            end: t('notificationMenu.content.scoreboard_created.titleEnd'),
          },
          description: {
            bold: item.matchTitle,
          },
        };
      case StudioEventType.SCOREBOARD_LIVE:
        return {
          title: {
            bold: item.channelName,
            end: t('notificationMenu.content.scoreboard_live.titleEnd'),
          },
          description: {
            bold: item.matchTitle,
          },
        };
      case StudioEventType.EVENT_CREATED:
        return {
          title: {
            bold: item.channelName,
            end: t('notificationMenu.content.event_created.titleEnd'),
          },
          description: {
            bold: item.eventTitle,
          },
        };
      case StudioEventType.FOLLOWING:
        return {
          title: {
            start: t('notificationMenu.content.following.titleStart'),
          },
          description: {
            bold: item.channelName,
            end: t('notificationMenu.content.following.descriptionEnd'),
          },
        };
      case StudioEventType.LIKE:
        return {
          title: {
            bold: item.channelName,
            end: t('notificationMenu.content.like.titleEnd'),
          },
          description: {
            bold: item.matchTitle,
          },
        };
      case StudioEventType.CHANNEL_PERMISSION_REQUEST: {
        return {
          title: {
            start: t(
              'notificationMenu.content.channel_permission_invited.titleStart',
            ),
          },
          description: {
            bold: item.channelName,
            end: t(
              'notificationMenu.content.channel_permission_invited.descriptionEnd',
            ),
          },
        };
      }
      case StudioEventType.CHANNEL_PERMISSION_ACCEPTED:
        return {
          title: {
            start: t(
              'notificationMenu.content.channel_permission_accepted.titleStart',
            ),
          },
          description: {
            bold: item.channelName,
            end: t(
              'notificationMenu.content.channel_permission_accepted.descriptionEnd',
            ),
          },
        };
      case StudioEventType.CHANNEL_PERMISSION_RESTRICTED:
        return {
          title: {
            start: t(
              'notificationMenu.content.channel_permission_restricted.titleStart',
            ),
          },
          description: {
            bold: item.channelName,
            end: t(
              'notificationMenu.content.channel_permission_restricted.descriptionEnd',
            ),
          },
        };
      case StudioEventType.SCOREBOARD_INCORRECT:
        return {
          title: {
            start: t(
              'notificationMenu.content.scoreboard_incorrect.titleStart',
            ),
          },
          description: {
            bold: item.matchTitle,
          },
        };

      default:
        return {
          title: {
            start: item.title,
          },
          description: {
            start: item.body,
          },
        };
    }
  }
};
