import type { ReactNode} from 'react';
import { useState } from 'react';

import useTranslation from 'next-translate/useTranslation';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import { useRouter } from 'next/router';

import CaptionIcon from '@mui/icons-material/ClosedCaptionOutlined';
import EventIcon from '@mui/icons-material/EmojiEventsOutlined';
import ScoreboardIcon from '@mui/icons-material/ScoreboardOutlined';
import type {
  CSSObject,
  SxProps} from '@mui/material';
import {
  Backdrop,
  Box,
  Button,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Typography,
  useTheme,
} from '@mui/material';

import CustomCircularProgress from 'components/atoms/CustomCircularProgress';
import { AddIcon } from 'components/atoms/Icons';
import CustomAdCard from 'components/organisms/GoogleAds/CustomAdCard';

import useActiveAuth from 'hooks/useActiveAuth';
import useCheckDevice from 'hooks/useCheckDevice';

import type { CaptionDialogProps } from 'types/caption';

const CaptionGenerateDialog = dynamic<Omit<CaptionDialogProps, 'setting'>>(
  () =>
    import('components/organisms/CaptionDialog').then(
      (module) => module.CaptionGenerateDialog,
    ),
  { loading: () => <CustomCircularProgress /> },
);
const MatchGeneratePopup = dynamic(
  () => import('components/organisms/MatchGeneratePopup'),
  { loading: () => <CustomCircularProgress /> },
);

const SideMenuCreateButton = ({ onClose }: { onClose: () => void }) => {
  const { isAuthenticatedUser, isAuthProcessComplete, signIn } =
    useActiveAuth();
  const router = useRouter();
  const { t } = useTranslation('common');

  const [isCaptionPopupOpen, setIsCaptionPopupOpen] = useState(false);
  const [isMatchPopupOpen, setIsMatchPopupOpen] = useState(false);

  const createMenuList: {
    className: string;
    id: 'match' | 'caption' | 'event';
    handleClickMenu: () => void;
  }[] = [
    {
      className: 'gtm-try-create-match-btn',
      id: 'match',
      handleClickMenu: () => setIsMatchPopupOpen(true),
    },
    {
      className: 'gtm-try-create-event-btn',
      id: 'event',
      handleClickMenu: () => router.push('/event/create'),
    },
    {
      className: 'gtm-try-create-caption-btn',
      id: 'caption',
      handleClickMenu: () => setIsCaptionPopupOpen(true),
    },
  ];

  return (
    <>
      <List
        aria-labelledby="nav-list"
        subheader={
          <ListSubheader
            disableSticky
            component="div"
            id="nav-subheader"
            sx={{ p: 0 }}
          >
            <Typography
              variant="chip"
              sx={{
                display: 'flex',
                alignItems: 'center',
                pl: 3,
                height: '36px',
                background:
                  'linear-gradient(115deg, #37C556 13.75%, #15B3B3 82.09%)',
                fontWeight: 700,
                color: 'white',
                mb: 1.5,
              }}
            >
              {t('createMenu.title')}
            </Typography>
          </ListSubheader>
        }
        sx={{ p: 0, cursor: isAuthProcessComplete ? 'pointer' : 'wait' }}
      >
        {createMenuList.map(({ className, id, handleClickMenu }) => (
          <ListItemButton
            key={id}
            disabled={!isAuthProcessComplete}
            className={className}
            component="li"
            onClick={() => {
              if (!isAuthenticatedUser) signIn();
              else {
                onClose();
                handleClickMenu();
              }
            }}
            sx={{
              p: '0px 24px',
              height: '48px',
              borderRadius: 0,
            }}
          >
            <ListItemIcon
              sx={{ minWidth: 48, svg: { width: '20px', color: 'gray.400' } }}
            >
              <AddIcon />
            </ListItemIcon>
            <ListItemText primary={t(`createMenu.short.${id}`)} />
          </ListItemButton>
        ))}
      </List>

      {isMatchPopupOpen ? (
        <MatchGeneratePopup
          open={isMatchPopupOpen}
          close={() => setIsMatchPopupOpen(false)}
        />
      ) : null}

      {isCaptionPopupOpen ? (
        <CaptionGenerateDialog
          onClose={() => setIsCaptionPopupOpen(false)}
          onSuccess={() => {
            setIsCaptionPopupOpen(false);
          }}
          open={isCaptionPopupOpen}
        />
      ) : null}
    </>
  );
};

const MobileCreateButton = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const { isAuthenticatedUser, isSubscribedUser, signIn } = useActiveAuth();
  const { push } = useRouter();
  const { t } = useTranslation('common');

  const [isCaptionPopupOpen, setIsCaptionPopupOpen] = useState(false);
  const [isMatchPopupOpen, setIsMatchPopupOpen] = useState(false);

  const handleClickBackDrop = () => {
    onClose();
  };

  const createMenuList: {
    className: string;
    id: 'match' | 'caption' | 'event';
    style: SxProps;
    icon: ReactNode;
    handleClickMenu: () => void;
  }[] = [
    {
      className: 'gtm-try-create-match-btn',
      id: 'match',
      style: {
        position: 'absolute',
        bottom: '46px',
        left: '80px',
        button: {
          bgcolor: 'secondaryColor.1',
          '&:hover': {
            bgcolor: 'secondaryColor.1',
          },
        },
      },
      icon: <ScoreboardIcon />,
      handleClickMenu: () => {
        setIsMatchPopupOpen(true);
      },
    },
    {
      className: 'gtm-try-create-event-btn',
      id: 'event',
      style: {
        position: 'absolute',
        bottom: '90px',
        button: {
          bgcolor: 'primaryColor.500',
          '&:hover': {
            bgcolor: 'primaryColor.500',
          },
        },
      },
      icon: <EventIcon />,
      handleClickMenu: () => {
        push('/event/create');
      },
    },
    {
      className: 'gtm-try-create-caption-btn',
      id: 'caption',
      style: {
        position: 'absolute',
        bottom: '46px',
        right: '80px',
        button: {
          bgcolor: 'secondaryColor.3',
          '&:hover': {
            bgcolor: 'secondaryColor.3',
          },
        },
      },
      icon: <CaptionIcon />,
      handleClickMenu: () => {
        setIsCaptionPopupOpen(true);
      },
    },
  ];

  return (
    <>
      <Backdrop
        open={open}
        onClick={handleClickBackDrop}
        sx={{
          zIndex: 1000,
          bottom: '60px',
          background:
            'linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.82) 78.35%)',
        }}
      >
        {!isSubscribedUser && open ? (
          <Box sx={{ mb: 8 }}>
            <CustomAdCard
              adId="모든_생성하기버튼상단_카드_모바일"
              width="300px"
              height="200px"
            />
          </Box>
        ) : null}

        {createMenuList.map(
          ({ className, id, icon, style, handleClickMenu }) => (
            <Box
              key={id}
              sx={{
                ...style,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                height: '52px',
                zIndex: 1001,
              }}
            >
              <Button
                className={className}
                disableRipple
                color="inherit"
                sx={{
                  width: '52px',
                  height: '52px',
                  minWidth: '52px',
                  minHeight: '52px',
                  borderRadius: '52px',
                  transform: open ? 'scale(1)' : 'scale(0.5)',
                  transition: 'transform .3s',
                  svg: {
                    color: 'white',
                  },
                }}
                onClick={() => {
                  if (!isAuthenticatedUser) signIn();
                  else handleClickMenu();
                }}
              >
                {icon}
              </Button>
              <Typography sx={{ fontSize: '12px', mt: '6px', color: 'white' }}>
                {t(`createMenu.short.${id}`)}
              </Typography>
            </Box>
          ),
        )}
      </Backdrop>

      {isMatchPopupOpen ? (
        <MatchGeneratePopup
          open={isMatchPopupOpen}
          close={() => setIsMatchPopupOpen(false)}
        />
      ) : null}

      {isCaptionPopupOpen ? (
        <CaptionGenerateDialog
          onClose={() => setIsCaptionPopupOpen(false)}
          onSuccess={() => {
            setIsCaptionPopupOpen(false);
          }}
          open={isCaptionPopupOpen}
        />
      ) : null}
    </>
  );
};

const MainPageTopCreateButton = () => {
  const { isAuthenticatedUser, isAuthProcessComplete, signIn } =
    useActiveAuth();
  const { push } = useRouter();
  const { t } = useTranslation('common');
  const {
    palette: { mode },
  } = useTheme();
  const { isSmToMd } = useCheckDevice();

  const [isCaptionPopupOpen, setIsCaptionPopupOpen] = useState(false);
  const [isMatchPopupOpen, setIsMatchPopupOpen] = useState(false);

  const createMenuList: {
    className: string;
    id: 'match' | 'caption' | 'event';
    style: SxProps;
    hoverStyle: CSSObject;
    handleClickMenu: () => void;
  }[] = [
    {
      className: 'gtm-try-create-match-btn',
      id: 'match',
      style: { borderColor: mode === 'dark' ? '#71A901' : '#93DC00' },
      hoverStyle: {
        bgcolor: { md: 'secondaryColor.1' },
        borderColor: '#88CB00',
        '.mainText': {
          display: { md: 'none', lg: 'block' },
          color: { md: 'black' },
        },
        '.hoverText': { display: { md: 'block', lg: 'none' }, color: 'black' },
        '.subText': { color: 'black' },
      },
      handleClickMenu: () => {
        setIsMatchPopupOpen(true);
      },
    },
    {
      className: 'gtm-try-create-event-btn',
      id: 'event',
      style: {
        whiteSpace: 'nowrap',
        borderColor: mode === 'dark' ? '#269D40' : '#37C556',
      },
      hoverStyle: {
        bgcolor: { md: 'primaryColor.500' },
        borderColor: '#31B74E',
        '.mainText': {
          display: { md: 'none', lg: 'block' },
          color: { md: 'black' },
        },
        '.hoverText': { display: { md: 'block', lg: 'none' }, color: 'black' },
        '.subText': { color: 'black' },
      },
      handleClickMenu: () => {
        push('/event/create');
      },
    },
    {
      className: 'gtm-try-create-caption-btn',
      id: 'caption',
      style: { borderColor: mode === 'dark' ? '#1E62A6' : '#083F77' },
      hoverStyle: {
        bgcolor: { md: mode === 'dark' ? '#315286' : '#083F77' },
        borderColor: '#062F58',
        '.mainText': {
          display: { md: 'none', lg: 'block' },
          color: { md: '#FCFCFC' },
        },
        '.hoverText': {
          display: { md: 'block', lg: 'none' },
          color: '#FCFCFC',
        },
        '.subText': { color: '#FCFCFC' },
      },
      handleClickMenu: () => {
        setIsCaptionPopupOpen(true);
      },
    },
  ];

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { sm: 'row', lg: 'column' },
          gap: { sm: '11px', md: '16px', lg: '24px' },
          cursor: isAuthProcessComplete ? 'default' : 'wait',
        }}
      >
        {createMenuList.map(
          ({ className, id, style, hoverStyle, handleClickMenu }) => (
            <Button
              disabled={!isAuthProcessComplete}
              key={id}
              className={className}
              sx={{
                display: 'flex',
                opacity: isAuthProcessComplete ? 1 : 0.5,
                width: { sm: '166px', md: '262px', lg: '352px' },
                height: { sm: '59px', md: '105px', lg: '141px' },
                borderRadius: '10px',
                bgcolor: mode === 'dark' ? '#212121' : '#FCFCFC',
                border: '2px solid',
                pl: { sm: '14px', md: '18px', lg: '21px' },
                pr: { sm: '10px', md: '16px', lg: '36px' },
                boxShadow: '4px 4px 10px 0px rgba(0, 0, 0, 0.10)',
                ':hover': {
                  pr: { lg: '22px' },
                  '.mobileImage, .tabletImage': { display: { md: 'none' } },
                  '.pcImage': { display: { lg: 'none' } },
                  '.pcHoverImage': { display: { lg: 'flex' } },
                  ...hoverStyle,
                },
                ...style,
              }}
              onClick={() => {
                if (!isAuthenticatedUser) signIn();
                else handleClickMenu();
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 1,
                  textAlign: 'left',
                  flex: 1,
                }}
              >
                <Typography
                  className="mainText"
                  sx={{
                    color: 'themeInverseColor',
                    fontSize: { sm: '14px', md: '18px', lg: '20px' },
                    fontWeight: 700,
                  }}
                >
                  {t(
                    `createButton.${id}.main.${
                      isSmToMd ? 'tablet' : 'desktop'
                    }`,
                  )}
                </Typography>
                <Typography
                  variant="body1Bold"
                  className="hoverText"
                  sx={{ display: 'none' }}
                >
                  {t(`createButton.${id}.sub`)}
                </Typography>
                <Typography
                  className="subText"
                  variant="body2"
                  sx={{
                    display: { sm: 'none ', lg: 'block' },
                    color: mode === 'dark' ? 'white' : '#60606F',
                  }}
                >
                  {t(`createButton.${id}.sub`)}
                </Typography>
              </Box>
              <Box
                className="mobileImage"
                sx={{
                  display: { sm: 'flex', md: 'none' },
                  width: '35px',
                  height: '35px',
                }}
              >
                <Image
                  priority
                  alt={`create ${id} image`}
                  src={`/img/icons/${id}Icon.png`}
                  width={272}
                  height={272}
                />
              </Box>
              <Box
                className="tabletImage"
                sx={{
                  display: { sm: 'none', md: 'flex', lg: 'none' },
                  width: '60px',
                  height: '60px',
                }}
              >
                <Image
                  priority
                  alt={`create ${id} image`}
                  src={`/img/icons/${id}Icon.png`}
                  width={272}
                  height={272}
                />
              </Box>
              <Box
                className="pcImage"
                sx={{
                  display: { sm: 'none', lg: 'flex' },
                  width: '68px',
                  height: '68px',
                }}
              >
                <Image
                  priority
                  alt={`create ${id} image`}
                  src={`/img/icons/${id}Icon.png`}
                  width={272}
                  height={272}
                />
              </Box>
              <Box
                className="pcHoverImage"
                sx={{ display: 'none', width: '95px', height: '95px' }}
              >
                <Image
                  priority
                  alt={`create ${id} active image`}
                  src={`/img/icons/${id}ActiveIcon.png`}
                  width={380}
                  height={380}
                />
              </Box>
            </Button>
          ),
        )}
      </Box>

      {isMatchPopupOpen ? (
        <MatchGeneratePopup
          open={isMatchPopupOpen}
          close={() => setIsMatchPopupOpen(false)}
        />
      ) : null}

      {isCaptionPopupOpen ? (
        <CaptionGenerateDialog
          onClose={() => setIsCaptionPopupOpen(false)}
          onSuccess={() => {
            setIsCaptionPopupOpen(false);
          }}
          open={isCaptionPopupOpen}
        />
      ) : null}
    </>
  );
};

const CreateMenu = {
  SideMenu: SideMenuCreateButton,
  Mobile: MobileCreateButton,
  MainPageTop: MainPageTopCreateButton,
};

export default CreateMenu;
