import { useCallback } from 'react';

import useAxios from 'hooks/useAxios';

import type {
  ChannelCategoryType,
  ChannelLinkType,
  ManagedChannelOwnerType,
  MyChannelManagerType,
} from 'types/channel';

export const GET_MY_CHANNEL_MANAGER_LIST_KEY = ['myChannel', 'managerList'];
export const GET_MANAGED_CHANNEL_LIST_KEY = ['managedChannelList'];

const useChannelApi = () => {
  const { studioAuthAxiosV1 } = useAxios();

  const updateChannelInfo = useCallback(
    async (
      uid: string,
      channelInfo: {
        channelName?: string;
        channelDescription?: string | null;
        interests?: string[];
        bannerImageUrl?: string | null;
        category?: ChannelCategoryType;
        address?: string | null;
        placeId?: string;
        detailAddress?: string | null;
      },
    ) =>
      await studioAuthAxiosV1({
        method: 'PATCH',
        url: `/channel/${uid}`,
        data: channelInfo,
      }),
    [studioAuthAxiosV1],
  );

  const updateChannelLink = useCallback(
    async (body: {
      createChannelLink: ChannelLinkType[];
      updateChannelLink: ChannelLinkType[];
    }) =>
      await studioAuthAxiosV1({
        method: 'POST',
        url: '/channel/channelLink',
        data: body,
      }),
    [studioAuthAxiosV1],
  );

  const deleteChannelLink = useCallback(
    async ({
      channelId,
      channelLinks,
    }: {
      channelId: number;
      channelLinks: number[];
    }) =>
      await studioAuthAxiosV1({
        method: 'DELETE',
        url: '/channel/channelLink',
        data: {
          channelLinks,
        },
        params: {
          channelId,
        },
      }),
    [studioAuthAxiosV1],
  );

  const deleteInvitedChannel = useCallback(
    async (id: number) =>
      await studioAuthAxiosV1({
        method: 'DELETE',
        url: `/channel/permission/${id}`,
      }),
    [studioAuthAxiosV1],
  );

  const addChannelManager = useCallback(
    async (nickname: string) =>
      await studioAuthAxiosV1({
        method: 'POST',
        url: `/channel/permission/${nickname}`,
      }),
    [studioAuthAxiosV1],
  );

  const getMyChannelManagerList = useCallback(
    async () =>
      await studioAuthAxiosV1<MyChannelManagerType[]>({
        method: 'GET',
        url: '/channel/permission/manager',
      }),
    [studioAuthAxiosV1],
  );

  const getManagedChannelList = useCallback(
    async () =>
      await studioAuthAxiosV1<ManagedChannelOwnerType[]>({
        method: 'GET',
        url: '/channel/permission/accessible',
      }),
    [studioAuthAxiosV1],
  );

  return {
    updateChannelInfo,
    updateChannelLink,
    deleteChannelLink,
    deleteInvitedChannel,
    addChannelManager,
    getMyChannelManagerList,
    getManagedChannelList,
  };
};

export default useChannelApi;
