import type { MouseEvent} from 'react';
import { useEffect } from 'react';

import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';

import MembershipIcon from '@mui/icons-material/CreditCard';
import HelpIcon from '@mui/icons-material/HelpOutline';
import MyChannelIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import {
  Avatar,
  Box,
  Button,
  Drawer,
  Skeleton,
  Typography,
} from '@mui/material';
import { setCookie } from 'cookies-next';

import {
  EventIcon,
  Home,
  StudioSymbolIcon,
  Translate,
} from 'components/atoms/Icons';
import StudioLogoIcon from 'components/atoms/Icons/StudioLogoIcon';
import MenuItemButton from 'components/atoms/MenuItemButton';
import { Divider } from 'components/atoms/Spacing';
import CreateMenu from 'components/molecules/CreateMenu';
import ComponentGuard from 'components/templates/ComponentGuard';
import Footer from 'components/templates/Footer';

import useActiveAuth from 'hooks/useActiveAuth';
import useCheckDevice from 'hooks/useCheckDevice';

import { getLanguageNameFromCodeByIntl } from 'utils/internationalization';

import ThemeChangeButton from './ThemeChangeButton';

const SideMenu = ({
  open,
  handleAnchorElUser,
  handleSideMenu,
}: {
  open: boolean;
  handleAnchorElUser: (event: MouseEvent<HTMLElement> | null) => void;
  handleSideMenu: (isOpen: boolean) => void;
}) => {
  const { channel, isAuthenticatedUser, user, signIn } = useActiveAuth();
  const { asPath, locale, query, pathname, push, replace } = useRouter();
  const { t } = useTranslation('common');
  const { isMobile, isPc } = useCheckDevice();

  const toggleDrawer =
    (open: boolean) => (event: KeyboardEvent | MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as KeyboardEvent).key === 'Tab' ||
          (event as KeyboardEvent).key === 'Shift')
      )
        return;

      handleSideMenu(open);
    };

  const handleCloseCreateMenu = () => {
    handleAnchorElUser(null);
  };

  const handleCloseSideMenu = () => {
    handleSideMenu(false);
  };

  const switchLanguage = () => {
    setCookie('locale', locale === 'en' ? 'ko' : 'en', {
      maxAge: 60 * 60 * 24 * 365 * 1000,
    });

    replace({ pathname: pathname, query: query }, asPath, {
      locale: locale === 'en' ? 'ko' : 'en',
    });

    handleCloseSideMenu();
  };

  useEffect(() => {
    handleCloseSideMenu();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asPath, isPc]);

  return (
    <Drawer
      id="gtm-side-area"
      open={open}
      onClose={toggleDrawer(false)}
      sx={{
        scrollbarWidth: 'none',
        '& ::-webkit-scrollbar': {
          display: 'none',
        },
        '& .MuiDrawer-paper': {
          backgroundImage: 'none',
          width: 270,
        },
      }}
    >
      <Box component="nav">
        <ComponentGuard
          loadingFallback={
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                p: '28px 24px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  gap: 2,
                }}
              >
                <Skeleton variant="circular" width={56} height={56} />
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                  }}
                >
                  <Skeleton variant="text" width={150} height={20} />
                  <Skeleton variant="text" width={150} height={20} />
                </Box>
              </Box>
            </Box>
          }
          nonLoginFallback={
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                p: 2,
              }}
            >
              <Box sx={{ py: 2 }}>
                <StudioLogoIcon sx={{ width: '190px', height: '30px' }} />
              </Box>
              <Button
                variant="contained"
                onClick={signIn}
                fullWidth
                sx={{
                  fontWeight: 900,
                  background:
                    'linear-gradient(115deg,#37C556 13.75%,#15B3B3 82.09%)',
                }}
              >
                {t('sign in')}
              </Button>
              <Typography variant="body2" sx={{ mt: 1, color: 'gray.400' }}>
                {t('profileMenu.loginText')}
              </Typography>
            </Box>
          }
        >
          <Box
            className="gtm-channel-detail-btn"
            onClick={() => {
              push(`/channel/@${channel.nickname}`);
              handleSideMenu(false);
            }}
            sx={{
              display: 'flex',
              alignItems: 'center',
              p: '28px 24px',
              cursor: 'pointer',
            }}
          >
            <Avatar
              alt={channel.channelName ?? 'channel-image'}
              src={user?.photoURL ?? ''}
              sx={{
                width: '56px',
                height: '56px',
                mr: 2,
                '& svg': { ml: 0 },
              }}
            />
            <Box>
              <Typography
                variant="h5Bold"
                sx={{
                  display: '-webkit-box',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  WebkitLineClamp: 1,
                  WebkitBoxOrient: 'vertical',
                  overflowWrap: 'anywhere',
                }}
              >
                {channel.channelName}
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  display: '-webkit-box',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  WebkitLineClamp: 1,
                  WebkitBoxOrient: 'vertical',
                  overflowWrap: 'anywhere',
                  color: 'gray.500',
                }}
              >
                @{channel.nickname}
              </Typography>
            </Box>
          </Box>
        </ComponentGuard>

        <CreateMenu.SideMenu onClose={handleCloseCreateMenu} />

        <Divider my="12px" />

        <MenuItemButton
          className="gtm-home-btn"
          buttonText={t(`menu.home`)}
          icon={<Home fontSize="small" />}
          selected={asPath === `/`}
          to={`/`}
          onClick={() => handleSideMenu(false)}
        />
        <MenuItemButton
          className="gtm-event-btn"
          buttonText={t(`menu.events`)}
          icon={<EventIcon fontSize="small" />}
          selected={asPath === `/event`}
          to={`/event`}
          onClick={() => handleSideMenu(false)}
        />

        <Divider my="12px" />

        {isAuthenticatedUser ? (
          <MenuItemButton
            className="gtm-channel-detail-btn"
            buttonText={t(`menu.myChannel`)}
            icon={<MyChannelIcon fontSize="small" />}
            selected={asPath === `/channel/@${channel.nickname}`}
            to={`/channel/@${channel.nickname}`}
            onClick={() => handleSideMenu(false)}
          />
        ) : null}
        <MenuItemButton
          className="gtm-membership-btn"
          buttonText={t('membership')}
          icon={<MembershipIcon fontSize="small" />}
          selected={asPath === '/membership'}
          to="/membership"
          onClick={() => handleSideMenu(false)}
        />

        <Divider my="12px" />

        <MenuItemButton
          className="gtm-about-btn"
          buttonText={t('menu.about')}
          icon={<StudioSymbolIcon fontSize="small" />}
          to="/about"
          onClick={() => handleSideMenu(false)}
        />

        <Divider my="12px" />

        {isAuthenticatedUser ? (
          <MenuItemButton
            className="gtm-setting-btn"
            buttonText={t('settings')}
            icon={<SettingsIcon fontSize="small" />}
            selected={asPath === '/settings'}
            to="/settings"
            onClick={() => handleSideMenu(false)}
          />
        ) : null}
        <MenuItemButton
          className="gtm-language-btn"
          buttonText={`${
            isMobile ? '' : `${t('language')}:`
          } ${getLanguageNameFromCodeByIntl(locale ?? 'en', locale)}`}
          icon={<Translate fontSize="small" />}
          onClick={switchLanguage}
        />
        <ThemeChangeButton />

        <MenuItemButton
          className="gtm-help-btn"
          buttonText={t('menu.help')}
          icon={<HelpIcon fontSize="small" />}
          target="_blank"
          to={
            locale === 'ko'
              ? 'https://cafe.naver.com/camerafilive/4384 '
              : 'https://blog.camerafi.com/p/tutorials-how-to-use-camerafi-studio.html'
          }
          onClick={() => handleSideMenu(false)}
        />

        <Box sx={{ p: '8px 16px' }}>
          <Footer.SideMenu sx={{ my: 6 }} />
        </Box>
      </Box>
    </Drawer>
  );
};
export default SideMenu;
