import type { MouseEvent, ReactNode } from 'react';
import { useState } from 'react';

import { useRouter } from 'next/router';

import { Box } from '@mui/material';

import LineBanner from 'components/atoms/LineBanner';
import CookieConsent from 'components/molecules/CookieConsent';
import LoginBanner from 'components/molecules/LoginBanner';
import MobilePopupBanner from 'components/molecules/MobilePopupBanner';
import AppBar from 'components/organisms/AppBar';
import AppInstallGuide from 'components/organisms/AppInstallGuide';
import WebSideAdCard from 'components/organisms/GoogleAds/WebSideAdCard';
import NavigationBar from 'components/organisms/NavigationBar';
import SideMenu from 'components/organisms/SideMenu';

import {
  hasCustomAttributesPage,
  usePageViewEventForGTM,
} from 'hooks/forGTM/usePageViewEventForGTM';
import useActiveAuth from 'hooks/useActiveAuth';
import useCheckDevice from 'hooks/useCheckDevice';
import useHotkey from 'hooks/useHotkey';
import useStudioAdSetting from 'hooks/useStudioAdSetting';

import { convertToHotkeySymbol } from 'utils/convertToHotkeySymbol';
import iOSCommunication from 'utils/iOSCommunication';

import Footer from './Footer';

const Layout = ({ children }: { children: ReactNode }) => {
  const { isAuthenticatedUser, isAuthProcessComplete } = useActiveAuth();
  const {
    hotkeyBoxRef,
    isHotkeyOn,
    isHotkeySettingMode,
    localHotkeyList,
    showHotkeyDialog,
  } = useHotkey();
  const { route } = useRouter();
  const { isMobile } = useCheckDevice();
  const {
    showLineBanner,
    lineBannerInfo,
    showMobilePopup,
    mobilePopupInfo,
    showAppInstallGuide,
    showAndroidGuide,
  } = useStudioAdSetting();

  const [anchorElUser, setAnchorElUser] = useState<HTMLElement | null>(null);
  const [sideMenuOpen, setSideMenuOpen] = useState(false);

  const isAboutPage = route === '/about';
  const isNavigationBarVisible =
    isMobile && ['/', '/event', '/channel/[handle]'].includes(route);
  const isPageActionButtonVisible =
    route === '/event/create' || route === '/event/edit/[editEventId]';

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (isHotkeySettingMode || showHotkeyDialog || !isHotkeyOn) return;

    e.preventDefault();
    const key = convertToHotkeySymbol(e.key);

    for (const id in localHotkeyList) {
      if (localHotkeyList[id] === key) {
        const button = document.getElementById(id);
        if (button) {
          button.focus();
          setTimeout(() => {
            button.click();
            hotkeyBoxRef.current?.focus();
          }, 200);
          break;
        }
      }
    }
  };

  usePageViewEventForGTM({
    enabled: !hasCustomAttributesPage(route),
    deps: route,
  });

  return (
    <Box
      ref={hotkeyBoxRef}
      tabIndex={0}
      onKeyDown={handleKeyDown}
      sx={{ outline: 'none' }}
    >
      {showLineBanner ? <LineBanner lineBannerInfo={lineBannerInfo} /> : null}

      {showMobilePopup ? (
        <MobilePopupBanner mobilePopupInfo={mobilePopupInfo} />
      ) : null}

      <AppBar
        anchorElUser={anchorElUser}
        handleAnchorElUser={(event: MouseEvent<HTMLElement> | null) => {
          setAnchorElUser(event ? event.currentTarget : null);
        }}
        handleSideMenu={(isOpen: boolean) => setSideMenuOpen(isOpen)}
      />

      {!isMobile ? (
        <SideMenu
          open={sideMenuOpen}
          handleAnchorElUser={(event: MouseEvent<HTMLElement> | null) => {
            setAnchorElUser(event ? event.currentTarget : null);
          }}
          handleSideMenu={(isOpen: boolean) => setSideMenuOpen(isOpen)}
        />
      ) : null}

      <Box
        id="gtm-main-area"
        component="main"
        sx={{
          position: 'relative',
          maxWidth: isAboutPage ? 'none' : '1200px',
          minWidth: '320px',
          minHeight: { xs: 'calc(100vh - 390px)', lg: '850px' },
          mx: 'auto',
        }}
      >
        {children}
        <WebSideAdCard />
      </Box>

      {/* 모바일에서 네비게이션바, 페이지 하단버튼이 있는 경우 푸터가 가려지는것을 방지하기 위한 여백 */}
      <Box
        sx={{
          mt: isAboutPage ? 0 : 10,
          pb: {
            xs: isNavigationBarVisible
              ? '80px'
              : isPageActionButtonVisible
                ? '120px'
                : 0,
            sm: 0,
          },
        }}
      >
        {isAboutPage ? <Footer.About /> : <Footer.Common />}
      </Box>

      {showAppInstallGuide ? (
        <AppInstallGuide showAndroidGuide={showAndroidGuide} />
      ) : null}
      {!isAboutPage &&
      !isAuthenticatedUser &&
      Boolean(isAuthProcessComplete) ? (
        <LoginBanner isNavigationBarVisible={isNavigationBarVisible} />
      ) : null}
      {!iOSCommunication.isIOSAPP ? <CookieConsent /> : null}
      {isNavigationBarVisible ? <NavigationBar /> : null}
    </Box>
  );
};

export default Layout;
