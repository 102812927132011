import { useEffect } from 'react';

type QueryEvents<RespT, ErrT> = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSuccess: (resp: RespT) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onError: (resp: ErrT) => any;
};

export default function useQueryEvents<RespT, ErrT>(
  query: {
    data?: RespT;
    error?: ErrT;
  },
  callbacks: Partial<QueryEvents<RespT, ErrT>>,
) {
  const { onSuccess, onError } = callbacks;

  useEffect(() => {
    if (query?.data && onSuccess) {
      onSuccess(query?.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query?.data]);

  useEffect(() => {
    if (query?.error && onError) {
      onError(query?.error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query?.error]);
}
